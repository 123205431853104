import { UserActions, UsersActionTypes } from './users.actions';
import { UsersState } from './users.state';

export const initialState: UsersState = {
  currentItem: null,
  entities: []
};

export function usersReducer(
  state: UsersState = initialState,
  action: UserActions
): UsersState {
  switch (action.type) {
    case UsersActionTypes.GetUsersSuccess:
      return {
        ...state,
        entities: action.payload
      };
    case UsersActionTypes.GetUserSuccess:
      return {
        ...state,
        currentItem: action.payload
      };
    case UsersActionTypes.CreateUserSuccess:
      return {
        ...state,
        entities: state.entities.concat(action.payload)
      };
    case UsersActionTypes.UpdateUserSuccess:
      return {
        ...state,
        entities: state.entities.map((user) => {
          if (user.username === action.payload.username) {
            return action.payload;
          }
          return user;
        })
      };
    default:
      return state;
  }
}
