import { ConnectionSourcesService } from '@app/connection-sources/services';
import React, { useState } from 'react';

import { ConnectionRow } from '../components/connection-row';
import { Connection } from '../models';

interface ConnectionRowContainerProps {
  item: Connection;
}

export const ConnectionRowContainer = ({
  item
}: ConnectionRowContainerProps) => {
  const [loading, setLoading] = useState(false);
  const [connectionSources, setConnectionSources] = useState([]);

  const getConnectionSources = async () => {
    setLoading(true);
    try {
      const result = await ConnectionSourcesService.getAll({
        integrationId: item.integration_id
      });
      setConnectionSources(result.data.results);
    } catch (e) {
      console.error('Unable to get connection sources');
    } finally {
      setLoading(false);
    }
  };
  return (
    <ConnectionRow
      item={item}
      loading={loading}
      connectionSources={connectionSources}
      getConnectionSources={getConnectionSources}
    />
  );
};
