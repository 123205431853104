import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { UserProfileChangePassword } from '../components';
import { ChangePasswordRequest } from '../models/change-password-request.model';
import { changePassword, setInvalidPassword } from '../state';

const UserProfileChangePasswordContainer = ({
  dispatch,
  accessToken,
  loading,
  invalidPassword
}) => {
  useEffect(() => {
    return () => {
      dispatch(setInvalidPassword(false));
    };
  }, [dispatch]);

  const handleSubmit = (payload: ChangePasswordRequest) => {
    const requestPayload: ChangePasswordRequest = {
      access_token: accessToken,
      old_password: payload.old_password,
      new_password: payload.new_password
    };
    dispatch(changePassword(requestPayload));
  };
  return (
    <UserProfileChangePassword
      loading={loading}
      invalidPassword={invalidPassword}
      onSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  accessToken: state.auth.accessToken,
  loading: getLoadingElse(state),
  invalidPassword: state.userProfile.invalidPassword
});

export default connect(mapStateToProps)(UserProfileChangePasswordContainer);
