import { DitQueryParams, Pagination } from '@ditmac/data';
import { DitTableFooter } from '@ditmac/is-ui';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ConnectionRowContainer } from '../containers/connection-row-container';
import { Connection } from '../models';

interface ConnectionScrollProps {
  list: Connection[];
  loading: boolean;
  pagination: Pagination;
  onFilterChange: (params: DitQueryParams) => void;
}

export const ConnectionScroll = ({
  list,
  loading,
  pagination,
  onFilterChange
}: ConnectionScrollProps) => {
  return (
    <>
      {loading ? (
        <div className="row">
          <div className="col">
            <Skeleton height={40} count={10} />
          </div>
        </div>
      ) : (
        <>
          <div className="row list-row bg-white border-bottom px-3">
            <div className="col">Name</div>
            <div className="col">Data</div>
            <div className="col">Last Update</div>
            <div className="col-2 flex-right">Actions</div>
          </div>

          {list?.map((connection, index) => (
            <ConnectionRowContainer key={index} item={connection} />
          ))}

          <DitTableFooter
            pagination={pagination}
            onPaginationChange={onFilterChange}
          />
        </>
      )}
    </>
  );
};
