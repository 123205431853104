import {
  AccountPreferencesActions,
  AccountPreferencesActionTypes
} from './account-preferences.actions';
import { AccountPreferencesState } from './account.preferences.state';

export const initialState: AccountPreferencesState = {
  hasPreferences: false,
  accountPrefernces: null,
  preferenceOptions: null
};

export function accountPreferencesReducer(
  state: AccountPreferencesState = initialState,
  action: AccountPreferencesActions
): AccountPreferencesState {
  switch (action.type) {
    case AccountPreferencesActionTypes.GetAccountPreferencesSuccess:
      return {
        ...state,
        accountPrefernces: action.payload || {},
        hasPreferences: !!action.payload
      };
    case AccountPreferencesActionTypes.CreateAccountPreferencesSuccess:
    case AccountPreferencesActionTypes.UpdateAccountPreferencesSuccess:
      return {
        ...state,
        accountPrefernces: action.payload
      };
    case AccountPreferencesActionTypes.GetPreferenceOptionsSuccess:
      return {
        ...state,
        preferenceOptions: action.payload
      };
    default:
      return state;
  }
}
