import {
  Auth,
  initInterceptors,
  isNotSignedGuard,
  isSignedGuard
} from '@app/auth';
import { history, store } from '@app/state';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';

import ToastrContainer from './core/containers/toastr-container';
import { Spa } from './spa';

initInterceptors();
moment.locale('en');

export const App = () => {
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={'en'}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <GuardProvider guards={[isSignedGuard, isNotSignedGuard]}>
              <Switch>
                <GuardedRoute path="/auth" meta={{ spa: true }}>
                  <Auth />
                </GuardedRoute>
                <GuardedRoute path="/spa" meta={{ auth: true }}>
                  <Spa />
                </GuardedRoute>
                <Redirect from="/" exact={true} to="/auth" />
              </Switch>
              <ToastrContainer />
            </GuardProvider>
          </ConnectedRouter>
        </BrowserRouter>
      </Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
