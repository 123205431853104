import './button.scss';

import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

interface DitButtonProps {
  label?: string;
  icon?: string;
  color?: 'primary' | 'secondary' | '';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  noBorder?: boolean;
  onClick?: any;
  loading?: boolean;
  disabled?: boolean;
}

export const DitButton = ({
  color,
  className,
  noBorder,
  type,
  loading,
  label,
  disabled,
  onClick
}: DitButtonProps) => {
  return (
    <Button
      color={color || 'default'}
      className={`dit-button ${className || ''}`}
      variant="contained"
      style={{ border: `${noBorder ? 'none' : '1px solid'}` }}
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {label}
      {!!loading && <CircularProgress className="ml-2 text-white" size={14} />}
    </Button>
  );
};
