export function getStart(currentPage: number): number {
  switch (currentPage % 5) {
    case 0:
      return currentPage - 5;
    case 1:
      return currentPage - 1;
    case 2:
      return currentPage - 2;
    case 3:
      return currentPage - 3;
    case 4:
      return currentPage - 4;
  }
}

export function getEnd(currentPage: number): number {
  switch (currentPage % 5) {
    case 0:
      return currentPage;
    case 1:
      return currentPage + 4;
    case 2:
      return currentPage + 3;
    case 3:
      return currentPage + 2;
    case 4:
      return currentPage + 1;
  }
}

export function getShowingNumber(
  currentPage: number,
  pageSize: number,
  totalCount: number
): number {
  const result = currentPage * pageSize;
  return totalCount > result ? result : totalCount;
}
