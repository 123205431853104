import { SelectOption } from '@ditmac/is-ui';
import Moment from 'moment';

import { FieldTypes } from '../enums';
import { ConnectionSourceRecord } from '../models';
import {
  ConnectionSourceDetailActions,
  ConnectionSourceDetailsActionTypes
} from './connection-source-detail.action.types';
import { ConnectionSourceDetailState } from './connection-source-detail.state';

export const initialState: ConnectionSourceDetailState = {
  entities: [],
  exportOptions: [],
  fieldOptions: [],
  loadingExport: false,
  loadingExportExcel: false,
  queryParams: null,
  pagination: null,
  views: [],
  currentView: null,
  userHasViews: false,
  viewToEdit: null
};

export function connectionSourceDetailReducer(
  state: ConnectionSourceDetailState = initialState,
  action: ConnectionSourceDetailActions
): ConnectionSourceDetailState {
  switch (action.type) {
    case ConnectionSourceDetailsActionTypes.GetConnectionSourceDetails:
      return {
        ...state,
        queryParams: action.payload.queryParams
      };
    case ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailsSuccess:
      return {
        ...state,
        entities: action.payload.results,
        pagination: action.payload.pagination,
        fieldOptions: getFields(action.payload.results) || state.fieldOptions
      };
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetails:
      return {
        ...state,
        loadingExport: true
      };
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetailsSuccess:
      return {
        ...state,
        exportOptions: action.payload,
        loadingExport: false
      };
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetailsFailed:
      return {
        ...state,
        loadingExport: false
      };
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcel:
      return {
        ...state,
        loadingExportExcel: true
      };
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelSuccess:
    case ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelFailed:
      return {
        ...state,
        loadingExportExcel: false
      };
    case ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViewsSuccess:
    case ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFieldsSuccess:
    case ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailViewSuccess:
      return {
        ...state,
        views: action.payload.views,
        userHasViews: !!action.payload.views,
        currentView: action.payload.views.find(
          (view) => view.name === action.payload.currentView
        )
      };
    case ConnectionSourceDetailsActionTypes.SetCurrentConnectionSourceDetailView:
      return {
        ...state,
        currentView: action.payload
      };
    case ConnectionSourceDetailsActionTypes.SetConnectionSourceDetailViewToEdit:
      return {
        ...state,
        viewToEdit: action.payload
      };
    case ConnectionSourceDetailsActionTypes.ResetConnectionDetails:
      return initialState;
    default:
      return state;
  }
}

function getFields(records: ConnectionSourceRecord[]): SelectOption[] {
  const record = records[0];
  return record
    ? Object.keys(record).map((option) => ({
        label: option,
        value: option,
        type: getFieldType(record[option])
      }))
    : null;
}

function getFieldType(value: any): FieldTypes {
  if (typeof value === 'boolean') {
    return FieldTypes.Boolean;
  }
  if (typeof value === 'number') {
    return FieldTypes.Number;
  }
  if (Moment(value, Moment.ISO_8601).isValid()) {
    return FieldTypes.Date;
  }
  return FieldTypes.String;
}
