import jwtDecode from 'jwt-decode';

import { AuthActions, AuthActionTypes } from './auth.actions';
import { AuthState } from './auth.state';

export const initialState: AuthState = {
  user: localStorage.getItem('IdToken')
    ? jwtDecode(localStorage.getItem('IdToken'))
    : '',
  accessToken: localStorage.getItem('accessToken'),
  idToken: localStorage.getItem('IdToken'),
  passwordRecoveryEmailSent: false,
  username: null
};

export function authReducer(
  state: AuthState = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.SignInSuccess:
      return {
        ...state,
        accessToken: action.payload.getAccessToken().getJwtToken(),
        idToken: action.payload.getIdToken().getJwtToken(),
        user: jwtDecode(action.payload.getIdToken().getJwtToken())
      };
    case AuthActionTypes.SignOut:
      return {
        ...state,
        accessToken: '',
        idToken: '',
        user: null
      };
    case AuthActionTypes.RequestPasswordRecoveryCodeSuccess:
      return {
        ...state,
        passwordRecoveryEmailSent: true,
        username: action.payload
      };
    case AuthActionTypes.ResetPasswordRecoveryEmailSent:
      return {
        ...state,
        passwordRecoveryEmailSent: false
      };
    default:
      return state;
  }
}
