import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { AppActions } from './app.actions';
import { appReducer } from './app.reducer';
import { AppState } from './app.state';

export const history = createBrowserHistory();

const composeEnhacers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  appReducer(history),
  composeEnhacers(
    applyMiddleware(
      routerMiddleware(history),
      thunk as ThunkMiddleware<AppState, AppActions>
    )
  )
);

export const persistor = persistStore(store);

export const getState = store.getState;
