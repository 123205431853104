import { DitButton, DitDialogLayout, DitInput } from '@ditmac/is-ui';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Role } from '../models';

export interface RoleFormProps {
  onCancel: () => void;
  onSubmit: (value: Role) => void;
}

const RoleFormValidation = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required()
});

export const RoleForm = ({ onCancel, onSubmit }: RoleFormProps) => {
  const initialValues = {
    name: '',
    description: ''
  };
  return (
    <DitDialogLayout size="sm">
      <Formik
        initialValues={initialValues}
        validationSchema={RoleFormValidation}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="row mb-3">
            <div className="col">
              <div className="h1 text-primary">Add Role</div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Field
                component={DitInput}
                label="Role name"
                placeholder="Enter name"
                name="name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Field
                component={DitInput}
                label="Role description"
                placeholder="Enter description"
                name="description"
              />
            </div>
          </div>
          <div className="row">
            <div className="col flex-right">
              <DitButton
                className="bg-white text-primary border border-primary mr-2"
                label="Cancel"
                onClick={() => onCancel()}
              />
              <DitButton color="primary" label="Add role" type="submit" />
            </div>
          </div>
        </Form>
      </Formik>
    </DitDialogLayout>
  );
};
