import { SekeletonCard } from '@app/skeletons';
import { DitButton, DitSelect, SelectOption } from '@ditmac/is-ui';
import { capitalize } from '@ditmac/utils';
import { Card } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { AccountPreferences, PreferenceOptions } from '../models';

interface LanguageTimeZoneProps {
  accountPreferences: AccountPreferences;
  preferenceOptions: PreferenceOptions;
  loading: boolean;
  loadingElse: boolean;
  onSubmit: (formValue: any) => void;
}

const LanguageTimeZoneValidation = Yup.object().shape({
  language: Yup.string().required(),
  date_format: Yup.string().required(),
  hour_format: Yup.string().required(),
  time_zone: Yup.string().required()
});

export const LanguageTimeZone = ({
  accountPreferences,
  preferenceOptions,
  loading,
  loadingElse,
  onSubmit
}: LanguageTimeZoneProps) => {
  const initialValues = {
    language: accountPreferences?.language,
    date_format: accountPreferences?.date_format,
    hour_format: accountPreferences?.hour_format,
    time_zone: accountPreferences?.time_zone
  };

  const options: Record<string, SelectOption[]> = {
    languages: preferenceOptions?.languages?.map((language) => ({
      label: capitalize(language),
      value: language
    })),
    date_format: preferenceOptions?.date_format?.map((format) => ({
      label: format,
      value: format
    })),
    hour_format: preferenceOptions?.hour_format?.map((format) => ({
      label: format,
      value: format
    })),
    time_zone: preferenceOptions?.time_zones?.map((zone) => ({
      label: capitalize(zone),
      value: zone
    }))
  };

  const controls = [
    { label: 'Language', name: 'language', options: options.languages },
    { label: 'Date Format', name: 'date_format', options: options.date_format },
    { label: 'Hour Format', name: 'hour_format', options: options.hour_format },
    { label: 'Time Zone', name: 'time_zone', options: options.time_zone }
  ];

  return (
    <>
      {loading ? (
        <SekeletonCard />
      ) : (
        <Card className="p-3">
          <Formik
            initialValues={initialValues}
            validationSchema={LanguageTimeZoneValidation}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="row mb-3">
                <div className="col text-primary">
                  <h4>Language and time zone</h4>
                </div>
              </div>

              {controls.map((control, index) => {
                return (
                  <div key={index} className="row py-2">
                    <div className="col-3 flex-left">
                      <span>{control.label}</span>
                    </div>
                    <div className="col-6">
                      <Field
                        component={DitSelect}
                        label={control.label}
                        hideLabel={true}
                        name={control.name}
                        options={control.options}
                        value={control.options?.filter(
                          (option) =>
                            accountPreferences &&
                            option.value === accountPreferences[control.name]
                        )}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="row py-2 mt-3">
                <div className="col-9 flex-right">
                  <DitButton
                    label="Save changes"
                    color="primary"
                    type="submit"
                    loading={loadingElse}
                  />
                </div>
              </div>
              <div className="row py-3"></div>
            </Form>
          </Formik>
        </Card>
      )}
    </>
  );
};
