import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MyAccountPersonalInformationContainer from './containers/my-account-personal-information-container';
import MyAccountUserCardContainer from './containers/my-account-user-card-container';

export const MyAccount = () => {
  return (
    <>
      <div className="row mb-5">
        <div className="col flex-left">
          <h5 className="mb-0 mr-2">My Account</h5>
          <i className="las la-user-circle"></i>
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <MyAccountUserCardContainer />
        </div>
        <div className="col-7">
          <Switch>
            <Redirect
              exact
              from="/spa/my-account"
              to="/spa/my-account/personal-information"
            />
            <Route path="/spa/my-account/personal-information" exact={true}>
              <MyAccountPersonalInformationContainer />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};
