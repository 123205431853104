import { MyAccountActions, MyAccountActionTypes } from './my-account.actions';
import { MyAccountState } from './my-account.state';

export const initialState: MyAccountState = {
  user: null
};

export function myAccountReducer(
  state: MyAccountState = initialState,
  action: MyAccountActions
): MyAccountState {
  switch (action.type) {
    case MyAccountActionTypes.GetMyAccountSuccess:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
}
