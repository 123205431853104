import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserRecordListContainer from './containers/user-record-list-container';

export const UserRecords = () => {
  return (
    <Switch>
      <Route path="/spa/settings/users/:id/records/all" exact={true}>
        <UserRecordListContainer />
      </Route>
    </Switch>
  );
};
