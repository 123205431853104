import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

interface DitTooltipProps {
  children: any;
  tooltip: string;
}

export const DitTooltip = ({ children, tooltip }: DitTooltipProps) => {
  return (
    <Tooltip title={tooltip} placement="top">
      {children}
    </Tooltip>
  );
};
