import { buildQueryParams, DitQueryParams } from '@ditmac/data';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { Role } from '../models';
import { GetRolesResponse } from '../models/get-roles-response.model';

export class RolesServices {
  static getAll(
    queryParams?: DitQueryParams
  ): Promise<AxiosResponse<GetRolesResponse>> {
    const params = buildQueryParams(queryParams);
    return axios.get<GetRolesResponse>(`${environment.apiUrl}/role`, {
      params
    });
  }

  static get(id: string): Promise<AxiosResponse<Role>> {
    return axios.get<Role>(`${environment.apiUrl}/role/${id}`);
  }

  static create(payload: Role): Promise<AxiosResponse<Role>> {
    return axios.post<Role>(`${environment.apiUrl}/role`, payload);
  }

  static update(payload: Partial<Role>): Promise<AxiosResponse<Role>> {
    return axios.put<Role>(
      `${environment.apiUrl}/role/${payload.role_id}`,
      payload
    );
  }
}
