import './input.scss';

import { capitalize } from '@ditmac/utils';
import { FieldInputProps, FormikProps, getIn } from 'formik';
import React from 'react';

import { DitError } from './error';

interface DitInputProps {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  hideLabel?: boolean;
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  autoCompleteOff?: boolean;
}

export const DitInput = ({
  id,
  label,
  type,
  placeholder,
  hideLabel,
  field,
  form,
  autoCompleteOff
}: DitInputProps) => {
  const errorMessage = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const hasError = errorMessage && touched;
  return (
    <div className="form-group my-2">
      {label && !hideLabel && <label>{label}</label>}
      <input
        id={id}
        type={!autoCompleteOff ? type : 'text'}
        className={`form-control ${hasError ? 'border-danger' : ''} ${
          type === 'password' && autoCompleteOff ? 'password' : ''
        }`}
        name={field.name}
        value={field.value}
        placeholder={placeholder}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      />
      {hasError && <DitError label={capitalize(errorMessage)} />}
    </div>
  );
};
