import { DitDialogLayout } from '@ditmac/is-ui';
import bytes from 'bytes';
import React from 'react';

import { ExportConnectionSourceDetailResponse } from '../models';

interface ConnectionSourceDetailExportDialogProps {
  exportOptions: ExportConnectionSourceDetailResponse[];
}

export const ConnectionSourceDetailExportDialog = ({
  exportOptions
}: ConnectionSourceDetailExportDialogProps) => {
  return (
    <DitDialogLayout>
      <div className="row mb-3">
        <div className="col">
          <div className="h1 text-primary">Export records</div>
        </div>
      </div>

      <div className="row font-weight-bold my-3">
        <div className="col">Name</div>
        <div className="col">Size</div>
      </div>

      {exportOptions.map((option, index) => {
        return (
          <div className="row my-3" key={index}>
            <div className="col">
              <a href={option.url} className="text-primary">
                <u>{option.name}</u>
              </a>
            </div>

            <div className="col">{bytes(Number(option.size))}</div>
          </div>
        );
      })}
    </DitDialogLayout>
  );
};
