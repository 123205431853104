import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';

import { ToastrPayload } from '../models';

interface ToastrProps {
  toastr: ToastrPayload;
  onClose: () => any;
}

export const Toastr = (props: ToastrProps) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClick={handleClose}
      onClose={handleClose}
    >
      <Alert severity={props.toastr.severity} variant="filled">
        {props.toastr.message}
      </Alert>
    </Snackbar>
  );
};
