import { BreadcumItem, DitButton } from '@ditmac/is-ui';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { Connection } from '../models';

interface ConnectionCardProps {
  item: Partial<Connection>;
}

export const ConnectionCard = ({ item }: ConnectionCardProps) => {
  const breadcum: BreadcumItem[] = [
    {
      name: item.name,
      route: `/spa/my-connections/${item.integration_id}`
    }
  ];
  return (
    <Card className="bg-white">
      <CardContent>
        <div className="row mb-4">
          <div className="col flex-left">{item.name}</div>
          <div className="col-auto flex-right">
            <Link
              to={`/spa/my-connections/${
                item.integration_id
              }?breadcum=${JSON.stringify(breadcum)}`}
              className="text-secondary"
            >
              <DitButton label="Open" color="primary" />
            </Link>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col flex-left">
            <i className="las la-tasks text-primary mr-2"></i>
            <span className="text-secondary">Data</span>
          </div>
          <div className="col flex-right">
            <i className="las la-calendar-day text-primary mr-2"></i>
            <span className="text-secondary">Last Update</span>
          </div>
        </div>

        <div className="row">
          <div className="col">{item.sources} source(s)</div>
          <div className="col flex-right">
            {Moment(item.updated_at).format('MMM D, YYYY')}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
