import { DitButton, DitError, DitInput } from '@ditmac/is-ui';
import Card from '@material-ui/core/Card';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ChangePasswordRequest } from '../models/change-password-request.model';

export interface UserProfileChangePasswordProps {
  loading: boolean;
  invalidPassword: boolean;
  onSubmit: (payload: Partial<ChangePasswordRequest>) => void;
}

const initialValues = {
  old_password: '',
  new_password: '',
  new_password_confirmation: ''
};

function getValidationSchema(values: Partial<ChangePasswordRequest>) {
  return Yup.object().shape({
    old_password: Yup.string().required().min(10),
    new_password: Yup.string().required().min(10),
    new_password_confirmation: Yup.string()
      .required()
      .min(10)
      .oneOf(
        [values.new_password],
        'The "New password" and "Verify password" fields have different values`'
      )
  });
}

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
}

function validate(values) {
  const validationSchema = getValidationSchema(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

export const UserProfileChangePassword = ({
  loading,
  invalidPassword,
  onSubmit
}: UserProfileChangePasswordProps) => {
  return (
    <Card className="py-3 px-4">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="row rounded-top">
            <div className="col">
              <h4 className="text-primary">Change User Password: </h4>
            </div>
          </div>
          <div className="row py-2">
            <div className="col-3 flex-left">
              <span>Old password</span>
            </div>
            <div className="col-6">
              <Field
                component={DitInput}
                label="Old password"
                hideLabel={true}
                placeholder="Old password"
                name="old_password"
                type="password"
              />
            </div>
          </div>
          <div className="row py-2">
            <div className="col-3 flex-left">
              <span>New password</span>
            </div>
            <div className="col-6">
              <Field
                component={DitInput}
                label="New password"
                hideLabel={true}
                placeholder="New password"
                name="new_password"
                type="password"
              />
            </div>
          </div>
          <div className="row py-2">
            <div className="col-3 flex-left">
              <span>Verify password</span>
            </div>
            <div className="col-6">
              <Field
                component={DitInput}
                label="Verify password"
                hideLabel={true}
                placeholder="Verify password"
                name="new_password_confirmation"
                type="password"
              />
            </div>
          </div>
          {invalidPassword && (
            <div className="row">
              <div className="col">
                <DitError label="The old password is incorrect" />
              </div>
            </div>
          )}
          <div className="row py-2 mt-3">
            <div className="col-9 flex-right">
              <DitButton
                label="Cancel"
                className="text-primary border-primary bg-white mr-2"
              />
              <DitButton
                label="Change User Password"
                color="primary"
                type="submit"
                loading={loading}
              />
            </div>
          </div>
          <div className="row py-4"></div>
        </Form>
      </Formik>
    </Card>
  );
};
