import { buildQueryParams, DitQueryParams } from '@ditmac/data';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { IntegrationResposne } from '../models';

export class MyConnectionsService {
  static getAll(
    payload?: DitQueryParams
  ): Promise<AxiosResponse<IntegrationResposne>> {
    const params = buildQueryParams(payload);
    return axios.get<IntegrationResposne>(`${environment.apiUrl}/integration`, {
      params
    });
  }
}
