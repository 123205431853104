import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SekeletonCard = () => {
  return (
    <Card className="bg-white">
      <CardContent>
        <div className="row mb-4">
          <div className="col">
            <Skeleton />
          </div>
          <div className="col-auto">
            <Skeleton />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <Skeleton />
          </div>
          <div className="col">
            <Skeleton />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Skeleton />
          </div>
          <div className="col">
            <Skeleton />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
