import {
  UserProfileActions,
  UserProfileActionTypes
} from './user-profile.actions';
import { UserProfileState } from './user-profile.state';

export const initialState: UserProfileState = {
  invalidPassword: false
};

export function userProfileReducer(
  state: UserProfileState = initialState,
  action: UserProfileActions
): UserProfileState {
  switch (action.type) {
    case UserProfileActionTypes.SetInvalidPassword:
      return {
        ...state,
        invalidPassword: action.payload
      };
    case UserProfileActionTypes.ChangePasswordFailed:
      return {
        ...state,
        invalidPassword: true
      };
    default:
      return state;
  }
}
