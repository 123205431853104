import { AccountPreferencesComponent } from '@app/account-preferences';
import { Roles } from '@app/roles';
import { Users } from '@app/users';
import { BreadcumItem, DitBreadcum } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const Settings = () => {
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  return (
    <>
      <div className="row mb-5">
        <div className="col flex-left">
          <h5 className="mb-0 mr-2">Settings</h5>
          <i className="las la-user"></i>
          <DitBreadcum breadcum={breadcum} />
        </div>
      </div>
      <Switch>
        <Route path="/spa/settings/users" component={Users}></Route>
        <Route path="/spa/settings/roles" component={Roles}></Route>
        <Route
          path="/spa/settings/account-preferences"
          component={AccountPreferencesComponent}
        ></Route>
      </Switch>
    </>
  );
};
