import {
  MyConnectionsActions,
  MyConnectionsActionTypes
} from './my-connections.actions';
import { MyConnectionsState } from './my-connections.state';

export const initialState: MyConnectionsState = {
  currentItem: null,
  entities: [],
  pagination: null
};

export function myConnectionsReducer(
  state: MyConnectionsState = initialState,
  action: MyConnectionsActions
): MyConnectionsState {
  switch (action.type) {
    case MyConnectionsActionTypes.GetMyConnectionsSuccess:
      return {
        ...state,
        entities: action.payload.results,
        pagination: action.payload.pagination
      };
    default:
      return state;
  }
}
