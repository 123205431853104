import { buildQueryParams } from '@ditmac/data';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { GetUserRecordsPayload, UserRecordsResponse } from '../models';

export class UserRecordsService {
  static getAll(
    payload: GetUserRecordsPayload
  ): Promise<AxiosResponse<UserRecordsResponse>> {
    const { username, queryParams } = payload;
    const params = buildQueryParams(queryParams);
    return axios.get(`${environment.apiUrl}/user/${username}/records`, {
      params
    });
  }
}
