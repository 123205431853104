import { accountPreferencesReducer } from '@app/account-preferences';
import { authReducer } from '@app/auth/state';
import { connectionSourceDetailReducer } from '@app/connection-source-detail';
import { connectionSourcesReducer } from '@app/connection-sources';
import { coreReducer } from '@app/core';
import { myAccountReducer } from '@app/my-account';
import { myConnectionsReducer } from '@app/my-connections';
import { rolesReducer } from '@app/roles/state';
import { userProfileReducer } from '@app/user-profile';
import { userRecordsReducer } from '@app/user-records';
import { usersReducer } from '@app/users';
import { metronic } from '@ditmac/metronic';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

export const appReducer = (history) =>
  combineReducers({
    accountPreferences: accountPreferencesReducer,
    auth: authReducer,
    builder: metronic.builder.reducer,
    connectionSourceDetail: connectionSourceDetailReducer,
    connectionSources: connectionSourcesReducer,
    core: coreReducer,
    myAccount: myAccountReducer,
    myConnections: myConnectionsReducer,
    roles: rolesReducer,
    userProfile: userProfileReducer,
    userRecords: userRecordsReducer,
    router: connectRouter(history),
    users: usersReducer
  });
