import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { User, UserForm } from '../models';

export class UserService {
  static getAll(): Promise<AxiosResponse<User[]>> {
    return axios.get<User[]>(`${environment.apiUrl}/user`);
  }

  static get(username: string): Promise<AxiosResponse<User>> {
    return axios.get<User>(`${environment.apiUrl}/user/${username}`);
  }

  static create(payload: UserForm): Promise<AxiosResponse<User>> {
    return axios.post<User>(`${environment.apiUrl}/user`, payload);
  }

  static update(payload: UserForm): Promise<AxiosResponse<User>> {
    return axios.put<User>(`${environment.apiUrl}/user`, payload);
  }

  static toggle(payload: {
    username: string;
    active: boolean;
  }): Promise<AxiosResponse<User>> {
    const { username, active } = payload;
    return axios.put<User>(
      `${environment.apiUrl}/user/${username}/${active ? 'enable' : 'disable'}`,
      payload
    );
  }
}
