import { DitQueryParams } from '@ditmac/data';

import { IntegrationResposne } from '../models';
import { MyConnectionsService } from '../services';

export enum MyConnectionsActionTypes {
  GetMyConnections = '[My Connections] Get my connections',
  GetMyConnectionsSuccess = '[My Connections] Get my connections success',
  GetMyConnectionsFailed = '[My Connections] Get my connections failed'
}

export interface GetMyConnections {
  type: MyConnectionsActionTypes.GetMyConnections;
  payload?: DitQueryParams;
}
export interface GetMyConnectionsSuccess {
  type: MyConnectionsActionTypes.GetMyConnectionsSuccess;
  payload: IntegrationResposne;
}
export interface GetMyConnectionsFailed {
  type: MyConnectionsActionTypes.GetMyConnectionsFailed;
}

export type MyConnectionsActions =
  | GetMyConnections
  | GetMyConnectionsSuccess
  | GetMyConnectionsFailed;

export const getMyConnections = (payload?: DitQueryParams) => async (
  dispatch
) => {
  dispatch({ type: MyConnectionsActionTypes.GetMyConnections, payload });
  try {
    const response = await MyConnectionsService.getAll(payload);
    dispatch(getMyConnectionsSuccess(response.data));
  } catch (e) {
    dispatch(getMyConnectionsFailed());
  }
};

export const getMyConnectionsSuccess = (
  payload: IntegrationResposne
): GetMyConnectionsSuccess => ({
  type: MyConnectionsActionTypes.GetMyConnectionsSuccess,
  payload
});

export const getMyConnectionsFailed = (): GetMyConnectionsFailed => ({
  type: MyConnectionsActionTypes.GetMyConnectionsFailed
});
