import { DitButton, DitInput } from '@ditmac/is-ui';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { SignInRequest } from '../models';

const signInFormValidation = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required()
});

interface SignInFormProps {
  loading: boolean;
  onSubmit: (loginRequest: SignInRequest) => any;
}

export const SignInForm = ({ loading, onSubmit }: SignInFormProps) => {
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={signInFormValidation}
      onSubmit={onSubmit}
    >
      {() => (
        <div className="kt-login__body">
          <div className="kt-login__form">
            <Form>
              <div className="row">
                <div className="col">
                  <h3 className="mb-5">IntegraSolve</h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4 className="text-primary mb-4">Sign In</h4>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Field
                    component={DitInput}
                    hideLabel={true}
                    name="username"
                    label="Your user"
                    placeholder="Your user"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Field
                    component={DitInput}
                    hideLabel={true}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col flex-left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <i
                            className="las la-circle text-secondary"
                            style={{ fontSize: '15px' }}
                          ></i>
                        }
                        checkedIcon={
                          <i
                            className="las la-check text-white bg-success rounded-circle p-1"
                            style={{ fontSize: '8px' }}
                          ></i>
                        }
                        name="remember"
                      />
                    }
                    label="Remember me"
                  />
                </div>
                <div className="col flex-right">
                  <Link to="/auth/request-password" className="text-secondary">
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col"></div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <DitButton
                    loading={loading}
                    color="primary"
                    label="Sign In"
                    type="submit"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <span>Don't have an account yet?</span>
                  <span className="text-primary"> Request it!</span>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
