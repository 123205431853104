import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { UserList } from '../components';
import { User } from '../models';
import { getUsers, toggleUser } from '../state/users.actions';
import UserFormContainer from './user-form-container';

const UserListContainer = ({ dispatch, entities, loading }) => {
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleAdd = () => {
    setUser(null);
    setOpen(true);
  };

  const handleEdit = (item: User) => {
    setUser(item);
    setOpen(true);
  };

  const handleToggle = (username: string, active: boolean) => {
    const payload = { username, active };
    dispatch(toggleUser(payload));
  };

  return (
    <>
      <UserList
        list={entities}
        loading={loading}
        breadcum={breadcum}
        onAddUser={handleAdd}
        onEditUser={handleEdit}
        onToggle={handleToggle}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <UserFormContainer
          id="user-form"
          item={user}
          onDone={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  entities: state.users.entities,
  loading: getLoading(state)
});

export default connect(mapStateToProps)(UserListContainer);
