import { BreadcumItem, DitIconButton, DitTooltip } from '@ditmac/is-ui';
import Moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { ConnectionSourceLastExecutions } from '../enums';
import { ConnectionSource } from '../models';

interface ConnectionSourceRowProps {
  breadcum: BreadcumItem[];
  connectionId: string;
  item: ConnectionSource;
  loading: boolean;
  onStartDataExtraction: () => void;
}

export const ConnectionSourceRow = ({
  item,
  breadcum,
  connectionId,
  loading,
  onStartDataExtraction
}: ConnectionSourceRowProps) => {
  const canStartDataExtraction =
    item.last_execution !== ConnectionSourceLastExecutions.InProgress;
  const canGoToDetails =
    item.last_execution !== ConnectionSourceLastExecutions.Never;

  const breadcumConst: BreadcumItem[] = [
    ...breadcum,
    {
      name: item.name,
      route: `/spa/my-connections/${connectionId}/${item.module_id}`
    }
  ];

  return (
    <div className={`row list-row px-4 font-weight-light`}>
      <div className="col">{item.name}</div>
      <div className="col">
        <span className="last-execution">
          {Moment(item.last_execution).isValid()
            ? Moment(item.last_execution).format('MMM D, YYYY')
            : item.last_execution}
        </span>
      </div>
      <div className="col-2">
        {canGoToDetails ? (
          <DitTooltip tooltip="Connection source detail">
            <Link
              to={`/spa/my-connections/${connectionId}/${
                item.module_id
              }?breadcum=${JSON.stringify(breadcumConst)}`}
              className="text-secondary"
            >
              <DitIconButton className="px-2 py-2 text-primary" icon="la-eye" />
            </Link>
          </DitTooltip>
        ) : (
          <DitTooltip tooltip="No data">
            <div>
              <DitIconButton
                className="px-2 py-2 text-primary no-data-button"
                icon="la-eye"
                disabled={true}
              />
            </div>
          </DitTooltip>
        )}

        <DitTooltip
          tooltip={
            canStartDataExtraction
              ? 'Start data extraction'
              : 'Data extraction in progress'
          }
        >
          <div>
            {loading }
            <DitIconButton
              color="primary"
              className="px-2 py-2 data-extraction-button"
              icon="la-file-download"
              disabled={!canStartDataExtraction || loading}
              onClick={() => onStartDataExtraction()}
            />
          </div>
        </DitTooltip>
      </div>
    </div>
  );
};
