import { ToggleUserPayload, UserForm } from '../models';
import { User } from '../models/user.model';
import { UserService } from '../services';

export enum UsersActionTypes {
  GetUsers = '[Users] Get users',
  GetUsersSuccess = '[Users] Get users success',
  GetUsersFailed = '[Users] Get users failed',
  GetUser = '[Users] Get user',
  GetUserSuccess = '[Users] Get user success',
  GetUserFailed = '[Users] Get user failed',
  CreateUser = '[Users] Create user',
  CreateUserSuccess = '[Users] Create user success',
  CreateUserFailed = '[Users] Create user failed',
  UpdateUser = '[Users] Update user',
  UpdateUserSuccess = '[Users] Update user success',
  UpdateUserFailed = '[Users] Update user failed',
  ToggleUser = '[Users] Toggle user',
  ToggleUserSuccess = '[Users] Toggle user success',
  ToggleUserFailed = '[Users] Toggle user failed'
}

export interface GetUsers {
  type: UsersActionTypes.GetUsers;
}
export interface GetUsersSuccess {
  type: UsersActionTypes.GetUsersSuccess;
  payload: User[];
}
export interface GetUsersFailed {
  type: UsersActionTypes.GetUsersFailed;
}
export interface GetUser {
  type: UsersActionTypes.GetUser;
}
export interface GetUserSuccess {
  type: UsersActionTypes.GetUserSuccess;
  payload: User;
}
export interface GetUserFailed {
  type: UsersActionTypes.GetUserFailed;
}
export interface CreateUser {
  type: UsersActionTypes.CreateUser;
  payload: UserForm;
}
export interface CreateUserSuccess {
  type: UsersActionTypes.CreateUserSuccess;
  payload: User;
}
export interface CreateUserFailed {
  type: UsersActionTypes.CreateUserFailed;
}
export interface UpdateUser {
  type: UsersActionTypes.UpdateUser;
  payload: UserForm;
}
export interface UpdateUserSuccess {
  type: UsersActionTypes.UpdateUserSuccess;
  payload: User;
}
export interface UpdateUserFailed {
  type: UsersActionTypes.UpdateUserFailed;
}
export interface ToggleUser {
  type: UsersActionTypes.ToggleUser;
  payload: ToggleUserPayload;
}
export interface ToggleUserSuccess {
  type: UsersActionTypes.ToggleUserSuccess;
}
export interface ToggleUserFailed {
  type: UsersActionTypes.ToggleUserFailed;
}

export type UserActions =
  | GetUsers
  | GetUsersSuccess
  | GetUsersFailed
  | GetUser
  | GetUserSuccess
  | GetUserFailed
  | CreateUser
  | CreateUserSuccess
  | CreateUserFailed
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailed
  | ToggleUser
  | ToggleUserSuccess
  | ToggleUserFailed;

export const getUsers = () => async (dispatch) => {
  dispatch({ type: UsersActionTypes.GetUsers });
  try {
    const response = await UserService.getAll();
    dispatch(getUsersSuccess(response.data));
  } catch (e) {
    dispatch(getUsersFailed());
  }
};

export const getUsersSuccess = (payload: User[]): GetUsersSuccess => ({
  type: UsersActionTypes.GetUsersSuccess,
  payload
});

export const getUsersFailed = (): GetUsersFailed => ({
  type: UsersActionTypes.GetUsersFailed
});

export const getUser = (payload: string) => async (dispatch) => {
  dispatch({ type: UsersActionTypes.GetUser, payload });
  try {
    const response = await UserService.get(payload);
    dispatch(getUserSuccess(response.data));
  } catch (e) {
    dispatch(getUserFailed());
  }
};

export const getUserSuccess = (payload: User): GetUserSuccess => ({
  type: UsersActionTypes.GetUserSuccess,
  payload
});

export const getUserFailed = (): GetUserFailed => ({
  type: UsersActionTypes.GetUserFailed
});

export const createUser = (payload: UserForm): CreateUser => ({
  type: UsersActionTypes.CreateUser,
  payload
});

export const createUserSuccess = (payload: User): CreateUserSuccess => ({
  type: UsersActionTypes.CreateUserSuccess,
  payload
});

export const createUserFailed = (): CreateUserFailed => ({
  type: UsersActionTypes.CreateUserFailed
});

export const updateUser = (payload: UserForm): UpdateUser => ({
  type: UsersActionTypes.UpdateUser,
  payload
});

export const updateUserSuccess = (payload: User): UpdateUserSuccess => ({
  type: UsersActionTypes.UpdateUserSuccess,
  payload
});

export const updateUserFailed = (): UpdateUserFailed => ({
  type: UsersActionTypes.UpdateUserFailed
});

export const toggleUser = (payload: ToggleUserPayload) => async (dispatch) => {
  dispatch({ type: UsersActionTypes.ToggleUser });
  try {
    await UserService.toggle(payload);
    dispatch(toggleUserSuccess());
  } catch (e) {
    dispatch(toggleUserFailed());
  }
};

export const toggleUserSuccess = (): ToggleUserSuccess => ({
  type: UsersActionTypes.ToggleUserSuccess
});

export const toggleUserFailed = (): ToggleUserFailed => ({
  type: UsersActionTypes.ToggleUserFailed
});
