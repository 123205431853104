import { DitButton, BreadcumItem } from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { User } from '../models';
import { UserRow } from './user-row';

interface UserListProps {
  list: User[];
  loading: boolean;
  breadcum: BreadcumItem[];
  onAddUser: () => void;
  onEditUser: (item: User) => void;
  onToggle: (username: string, active: boolean) => void;
}

export const UserList = ({
  list,
  loading,
  breadcum,
  onAddUser,
  onEditUser,
  onToggle
}: UserListProps) => {
  return (
    <>
      {loading ? (
        <Card>
          <div className="row py-3">
            <div className="col-3">
              <Skeleton />
            </div>
            <div className="col-3 offset-6">
              <Skeleton />
            </div>
          </div>
          <div className="row pb-3">
            <div className="col">
              <Skeleton height={40} count={10} />
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="row rounded-top py-3 px-4">
            <div className="col flex-left">
              <span className="text-primary">Users List</span>
              <span className="mx-3">|</span>
              <span>{list.length} Total</span>
            </div>
            <div className="col flex-right">
              {/* <Link to="/spa/settings/users/access-managment">
                <DitButton
                  className="mr-3 text-primary bg-white border border-primary"
                  label="Permissions"
                />
              </Link> */}

              {/* <DitButton
                label="Add User"
                color="primary"
                onClick={() => onAddUser()}
              /> */}
            </div>
          </div>

          {/* <div className="row pt-4 py-4 px-4 border-bottom">
            <div className="col-4 flex-left">
              <DitSearchbar className="border" />
            </div>
            <div className="col-4 flex-left">
              <span className="mr-2 text-black">Role: </span>
              <DitSelect className="mr-3" options={[]} />
              <span className="mr-2">Active: </span>
              <DitSelect options={[]} />
            </div>
          </div> */}

          <div className="row border-bottom px-3 py-3">
            <div className="col-2">Username</div>
            <div className="col-2">Name</div>
            <div className="col-1">Company</div>
            <div className="col-1">Title</div>
            <div className="col-1">Phone</div>
            <div className="col-2">Email</div>
            <div className="col-1">Role</div>
            <div className="col-1">Active</div>
            <div className="col-1">Actions</div>
          </div>

          {list.map((user, index) => (
            <UserRow
              key={index}
              item={user}
              breadcum={breadcum}
              onEdit={() => onEditUser(user)}
              onToggle={(username, active) => onToggle(username, active)}
            />
          ))}
          {/* <DitTableFooter /> */}
        </Card>
      )}
    </>
  );
};
