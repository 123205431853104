import { RolesActions, RolesActionTypes } from './roles.actions';
import { RolesState } from './roles.state';

export const initialState: RolesState = {
  currentItem: null,
  entities: [],
  pagination: null
};

export function rolesReducer(
  state: RolesState = initialState,
  action: RolesActions
): RolesState {
  switch (action.type) {
    case RolesActionTypes.GetRolesSuccess:
      return {
        ...state,
        entities: action.payload.results,
        pagination: action.payload.pagination
      };
    case RolesActionTypes.GetRoleSuccess:
      return {
        ...state,
        currentItem: action.payload
      };
    case RolesActionTypes.CreateRoleSuccess:
      return {
        ...state,
        currentItem: action.payload
      };
    case RolesActionTypes.UpdateRoleSuccess:
      return {
        ...state,
        entities: (state.entities || []).map((role) => {
          return role.role_id === action.payload.role_id
            ? { ...role, ...action.payload }
            : role;
        })
      };
    default:
      return state;
  }
}
