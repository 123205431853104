import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import { BreadcumItem } from '@ditmac/is-ui';
import React from 'react';
import { connect } from 'react-redux';

import { ConnectionSourceRow } from '../components/connection-source-row';
import { ConnectionSource } from '../models/connection-source.model';
import { startDataExtraction } from '../state/connection-sources.actions';

interface ConnectionSourceRowContainerProps {
  dispatch: any;
  loading: boolean;
  item: ConnectionSource;
  connectionId: string;
  breadcum: BreadcumItem[];
}

const ConnectionSourceRowContainer = ({
  dispatch,
  loading,
  item,
  connectionId,
  breadcum
}: ConnectionSourceRowContainerProps) => {
  const handleStartDataExtraction = (integrationId, moduleId: string) => {
    const payload = {
      integrationId,
      moduleId
    };
    dispatch(startDataExtraction(payload));
  };

  return (
    <ConnectionSourceRow
      item={item}
      connectionId={connectionId}
      breadcum={breadcum}
      loading={loading}
      onStartDataExtraction={() =>
        handleStartDataExtraction(item.integration_id, item.module_id)
      }
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: getLoadingElse(state)
});

export default connect(mapStateToProps)(ConnectionSourceRowContainer);
