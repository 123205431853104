import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { UserFormComponent } from '../components/';
import { User, UserForm } from '../models';
import { UserService } from '../services';
import {
  createUser,
  createUserFailed,
  createUserSuccess,
  updateUser,
  updateUserFailed,
  updateUserSuccess
} from '../state/users.actions';

interface UserFormContainerProps {
  item: User;
  loading?: boolean;
  onDone: () => void;
  dispatch?: (action) => void;
}

const UserFormContainer = ({
  item,
  loading,
  onDone,
  dispatch
}: UserFormContainerProps) => {
  const [userSaved, setUserSaved] = useState(false);
  const handleSubmit = async (userForm: UserForm) => {
    if (item) {
      dispatch(updateUser(userForm));
      try {
        const response = await UserService.update(userForm);
        dispatch(updateUserSuccess(response.data));
        setUserSaved(true);
      } catch (e) {
        dispatch(updateUserFailed());
      }
    } else {
      dispatch(createUser(userForm));
      try {
        const response = await UserService.create(userForm);
        dispatch(createUserSuccess(response.data));
        setUserSaved(true);
      } catch (e) {
        dispatch(createUserFailed());
      }
    }
  };
  return (
    <UserFormComponent
      item={item}
      loading={loading}
      done={userSaved}
      onDone={() => onDone()}
      onSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: getLoadingElse(state)
});

export default connect(mapStateToProps)(UserFormContainer);
