import { getLoading, getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import { Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RoleDetail } from '../components/role-detail';
import { Role } from '../models';
import { getRole, getRoleSuccess, updateRole } from '../state';

const RoleDetailContainer = ({ dispatch, item, loading, loadingElse }) => {
  const { id } = useParams();

  useEffect(() => {
    dispatch(getRole(id));
    return () => {
      dispatch(getRoleSuccess(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSubmit = (role: Role) => {
    dispatch(updateRole(role));
  };
  return (
    <>
      {item && !loading ? (
        <RoleDetail item={item} loading={loadingElse} onSubmit={handleSubmit} />
      ) : (
        <Card className="p-3">
          <Skeleton />
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  item: state.roles.currentItem,
  loading: getLoading(state),
  loadingElse: getLoadingElse(state)
});

export default connect(mapStateToProps)(RoleDetailContainer);
