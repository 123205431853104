export function sortObjectArrayAlphabetically(options: any[], fieldName: string): any[] {
  return (options || []).sort((a, b) => {
    const aLabel = a[fieldName]?.toLowerCase();
    const bLabel = b[fieldName]?.toLowerCase();

    if (aLabel < bLabel) {
      return -1;
    }

    if (aLabel > bLabel) {
      return 1;
    }

    return 0;
  });
}
