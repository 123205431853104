import { DitQueryParams, Pagination } from '@ditmac/data';
import { DitEmptyState, DitIconButton, DitSearchbar } from '@ditmac/is-ui';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Connection } from '../models';
import { ConnectionCards } from './connection-cards';
import { ConnectionScroll } from './connection-scroll';

enum MyConnectionListViewModes {
  cards = 'cards',
  list = 'list'
}

interface MyConnectionsListProps {
  list: Connection[];
  loading: boolean;
  pagination: Pagination;
  onFilterChange: (params: DitQueryParams) => void;
}

export const MyConnectionsList = ({
  list,
  loading,
  pagination,
  onFilterChange
}: MyConnectionsListProps) => {
  const [viewMode, setViewMode] = useState(MyConnectionListViewModes.list);
  const showSkeletons = loading && !pagination;
  return (
    <>
      <div className="row mb-3">
        <div className="col-4 text-primary">
          <h4>{showSkeletons ? <Skeleton /> : 'Integrated Data source(s)'}</h4>
        </div>
        <div className="col-1 offset-7">
          {showSkeletons ? (
            <Skeleton />
          ) : (
            <div className="flex-right">
              <DitIconButton
                icon="la-bars"
                className={`p-1 ${
                  viewMode === MyConnectionListViewModes.list
                    ? 'text-primary'
                    : ''
                }`}
                onClick={() => setViewMode(MyConnectionListViewModes.list)}
              />
              <DitIconButton
                icon="la-th-large"
                className={`p-1 ${
                  viewMode === MyConnectionListViewModes.cards
                    ? 'text-primary'
                    : ''
                }`}
                onClick={() => setViewMode(MyConnectionListViewModes.cards)}
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className="row mb-3">
        <div className="col-5 p-0">
          {showSkeletons ? (
            <Skeleton />
          ) : (
            <DitSearchbar onChange={onFilterChange} />
          )}
        </div>
      </div> */}

      {list.length || showSkeletons ? (
        <>
          {viewMode === MyConnectionListViewModes.cards && (
            <ConnectionCards list={list} loading={showSkeletons} />
          )}
          {viewMode === MyConnectionListViewModes.list && (
            <ConnectionScroll
              list={list}
              loading={showSkeletons}
              pagination={pagination}
              onFilterChange={onFilterChange}
            />
          )}
        </>
      ) : (
        <DitEmptyState message="No connections" />
      )}
    </>
  );
};
