import { CoreActionTypes } from './core.actions';
import { CoreState } from './core.state';

export const initialState: CoreState = {
  toastr: {
    message: '',
    severity: ''
  },
  loadingGet: 0,
  loadingElse: 0
};

export function coreReducer(
  state: CoreState = initialState,
  action
): CoreState {
  switch (action.type) {
    case CoreActionTypes.DisplayToastr:
      return {
        ...state,
        toastr: action.payload
      };
    case CoreActionTypes.ClearToastr:
      return {
        ...state,
        toastr: initialState.toastr
      };
    case CoreActionTypes.SetLoadingGet:
      return {
        ...state,
        loadingGet: action.payload ? state.loadingGet + 1 : state.loadingGet - 1
      };
    case CoreActionTypes.SetLoadingElse:
      return {
        ...state,
        loadingElse: action.payload ? state.loadingElse + 1 : state.loadingElse - 1
      };
    default:
      return state;
  }
}
