import { Card } from '@material-ui/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonList = () => {
  return (
    <Card>
      <div className="row py-4">
        <div className="col-3">
          <Skeleton />
        </div>
        <div className="col-2 offset-7">
          <Skeleton />
        </div>
      </div>

      <div className="row pb-3">
        <div className="col">
          <Skeleton height={40} count={10} />
        </div>
      </div>
    </Card>
  );
};
