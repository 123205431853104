import {
  BreadcumItem,
  DitIconButton,
  DitSwitch,
  DitTextTruncateTooltip,
  DitTooltip
} from '@ditmac/is-ui';
import { Avatar } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { User } from '../models';

interface UserRowProps {
  item: User;
  breadcum: BreadcumItem[];
  onEdit: () => void;
  onToggle: (username: string, active: boolean) => void;
}

export const UserRow = ({ item, breadcum, onEdit, onToggle }: UserRowProps) => {
  const componentBreadcum: BreadcumItem[] = [
    ...breadcum,
    {
      name: item.name,
      route: `/spa/settings/users/${item.username}`
    }
  ];
  return (
    <div
      key={item.username}
      className="row list-row py-3 px-3 font-weight-light"
    >
      <div className="col-2">
        <DitTextTruncateTooltip label={item.username} />
      </div>
      <div className="col-2">
        <Avatar className="mr-2">{item.name?.charAt(0).toUpperCase()}</Avatar>
        <DitTextTruncateTooltip label={item.name} />
      </div>
      <div className="col-1">
        <DitTextTruncateTooltip label={item.company} />
      </div>
      <div className="col-1">
        <DitTextTruncateTooltip label={item.title} />
      </div>
      <div className="col-1">
        <DitTextTruncateTooltip label={item.phone} />
      </div>
      <div className="col-2">
        <DitTextTruncateTooltip label={item.email} />
      </div>
      <div className="col-1">
        <DitTextTruncateTooltip label={item.role} />
      </div>
      <div className="col-1">
        <DitSwitch
          checked={item.enabled}
          onToggle={(active) => onToggle(item.username, active)}
        />
      </div>
      <div className="col-1">
        <DitTooltip tooltip="User profile">
          <Link
            to={`/spa/settings/users/${item.username}?breadcum=${JSON.stringify(
              componentBreadcum
            )}`}
            className="text-primary flex-left"
          >
            <DitIconButton className="px-2 py-2 text-primary" icon="la-eye" />
          </Link>
        </DitTooltip>
        <DitTooltip tooltip="Edit user">
          <div>
            <DitIconButton
              className="px-2 py-2 text-primary edit-button"
              icon="la-edit"
              onClick={() => onEdit()}
            />
          </div>
        </DitTooltip>
      </div>
    </div>
  );
};
