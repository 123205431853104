import { UserRecord } from '@app/user-records';
import {
  BreadcumItem,
  DitButton,
  DitEmptyState,
  DitTableHeaderColumn
} from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import Moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useRouteMatch } from 'react-router-dom';

interface UserProfileRecordListProps {
  list: UserRecord[];
  loading: boolean;
  breadcum: BreadcumItem[];
}

export const UserProfileRecordList = ({
  list,
  loading,
  breadcum
}: UserProfileRecordListProps) => {
  const match = useRouteMatch();
  const componentBreadcum: BreadcumItem[] = [
    ...breadcum,
    {
      name: 'All records',
      route: `${match.url}/all`
    }
  ];
  return (
    <Card className="py-3 px-4">
      {loading ? (
        <Skeleton height={40} count={10} />
      ) : (
        <>
          <div className="row py-3">
            <div className="col">
              <span className="text-primary">Last update:</span>
            </div>
            <div className="col flex-right">
              <Link
                to={{
                  pathname: `${match.url}/all`,
                  search: `breadcum=${JSON.stringify(componentBreadcum)}`
                }}
              >
                <DitButton label="All records" color="primary" />
              </Link>
            </div>
          </div>

          {list.length ? (
            <>
              <div className="row pt-3">
                <div className="col-3">
                  <DitTableHeaderColumn label="Connection" sortable={false} />
                </div>
                <div className="col-3">
                  <DitTableHeaderColumn
                    label="Facility Name"
                    sortable={false}
                  />
                </div>
                <div className="col-2">
                  <DitTableHeaderColumn label="Source" sortable={false} />
                </div>
                <div className="col-2">
                  <DitTableHeaderColumn label="Date" sortable={false} />
                </div>
                <div className="col-2">
                  <DitTableHeaderColumn label="Type" sortable={false} />
                </div>
              </div>

              {list.slice(0, 5).map((item, index) => {
                return (
                  <div key={index} className="row list-row py-3">
                    <div className="col-4">{item.action}</div>
                    <div className="col-4">
                      {Moment(item.created_at).format('MM/DD/YYYY')}
                    </div>
                    <div className="col-4">{item.record_type}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <DitEmptyState message="This user has no records" />
          )}

          <div className="row py-3"></div>
        </>
      )}
    </Card>
  );
};
