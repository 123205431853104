import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AccessManagmentListContainer from './containers/access-managment-list-container';

export const AccessManagment = () => {
  return (
    <Switch>
      <Route path="/spa/settings/users/access-managment" exact={true}>
        <AccessManagmentListContainer />
      </Route>
    </Switch>
  );
};
