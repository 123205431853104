import { ConnectionSource } from '@app/connection-sources/models';
import { BreadcumItem, DitButton } from '@ditmac/is-ui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import Moment from 'moment';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import ConnectionSourceRowContainer from '../../connection-sources/containers/connection-source-row-container';
import { Connection } from '../models';

interface ConnectionRowProps {
  item: Connection;
  loading: boolean;
  connectionSources: ConnectionSource[];
  getConnectionSources: () => void;
}

export const ConnectionRow = ({
  item,
  loading,
  connectionSources,
  getConnectionSources
}: ConnectionRowProps) => {
  const [expanded, setExpanded] = useState(false);
  const breadcum: BreadcumItem[] = [
    {
      name: item.name,
      route: `/spa/my-connections/${item.integration_id}`
    }
  ];

  const handleExpansion = () => {
    if (!connectionSources.length) {
      getConnectionSources();
    }
    setExpanded(!expanded);
  };
  return (
    <Accordion className="border-bottom" expanded={expanded}>
      <AccordionSummary className="row list-row bg-white font-weight-light px-3 border-0">
        <div className="col">{item.name}</div>
        <div className="col">{item.sources} source(s)</div>
        <div className="col">
          {Moment(item.updated_at).format('MMM D, YYYY')}
        </div>
        <div className="col flex-right">
          {/* <Link
            to={`/spa/my-connections/${
              item.integration_id
            }?breadcum=${JSON.stringify(breadcum)}`}
            className="text-secondary"
          > */}
          <DitButton
            color="primary"
            label={!expanded ? 'Open' : 'Close'}
            onClick={() => handleExpansion()}
          />
          {/* </Link> */}
        </div>
      </AccordionSummary>
      <AccordionDetails className="row d-block">
        {loading ? (
          <div className="row">
            <div className="col">
              <Skeleton height={40} count={5} />
            </div>
          </div>
        ) : (
          <>
            <div className="row list-row border px-4 w-100 font-weight-bold">
              <div className="col">Source Name</div>
              <div className="col">Last Update</div>
              <div className="col-2">Actions</div>
            </div>
            {connectionSources.map((source, index) => (
              <div key={index} className="row w-100 border-right border-left">
                <div className="col p-0">
                  <ConnectionSourceRowContainer
                    item={source}
                    connectionId={item.integration_id}
                    breadcum={breadcum}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
