import { DitQueryParams, Pagination } from '@ditmac/data';
import { BreadcumItem, DitEmptyState, DitTableFooter } from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import ConnectionSourceRowContainer from '../containers/connection-source-row-container';
import { ConnectionSource } from '../models';

interface ConnectionSourcesListProps {
  breadcum: BreadcumItem[];
  connectionName: string;
  connectionId: string;
  list: ConnectionSource[];
  loading: boolean;
  loadingElse: boolean;
  pagination: Pagination;
  onFilterChange: (queryParams: DitQueryParams) => void;
}

export const ConnectionSourcesList = ({
  breadcum,
  connectionName,
  connectionId,
  list,
  loading,
  loadingElse,
  pagination,
  onFilterChange
}: ConnectionSourcesListProps) => {
  return (
    <>
      {loading ? (
        <>
          <div className="row mb-3">
            <div className="col-4">
              <h4>
                <Skeleton />
              </h4>
            </div>
          </div>
          <Card>
            <div className="row pb-3">
              <div className="col">
                <Skeleton height={40} count={10} />
              </div>
            </div>
          </Card>
        </>
      ) : (
        <>
          <div className="row mb-3">
            <div className="col text-primary">
              <h4>{connectionName}</h4>
            </div>
          </div>

          <Card>
            {!list.length ? (
              <DitEmptyState message="This connection has no records" />
            ) : (
              <>
                <div className="row list-row border-bottom px-4">
                  <div className="col">Source Name</div>
                  <div className="col">Last Update</div>
                  <div className="col-2">Actions</div>
                </div>

                {list.map((source, index) => (
                  <ConnectionSourceRowContainer
                    key={index}
                    item={source}
                    connectionId={connectionId}
                    breadcum={breadcum}
                    loading={loadingElse}
                  />
                ))}

                <DitTableFooter
                  pagination={pagination}
                  onPaginationChange={onFilterChange}
                />
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
};
