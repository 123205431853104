import { GetUserRecordsPayload, UserRecordsResponse } from '../models';
import { UserRecordsService } from '../services/user-records.service';

export enum UserRecordsActionTypes {
  GetUserRecords = '[User Records] Get user records',
  GetUserRecordsSuccess = '[User Records] Get user records success',
  GetUserRecordsFailed = '[User Records] Get user records failed'
}

export interface GetUserRecords {
  type: UserRecordsActionTypes.GetUserRecords;
  payload: GetUserRecordsPayload;
}
export interface GetUserRecordsSuccess {
  type: UserRecordsActionTypes.GetUserRecordsSuccess;
  payload: UserRecordsResponse;
}
export interface GetUserRecordsFailed {
  type: UserRecordsActionTypes.GetUserRecordsFailed;
}

export type UserRecordsActions =
  | GetUserRecords
  | GetUserRecordsSuccess
  | GetUserRecordsFailed;

export const getUserRecords = (payload: GetUserRecordsPayload) => async (
  dispatch
) => {
  dispatch({ type: UserRecordsActionTypes.GetUserRecords, payload });
  try {
    const response = await UserRecordsService.getAll(payload);
    dispatch(getUserRecordsSuccess(response.data));
  } catch (e) {
    dispatch(getUserRecordsFailed());
  }
};

export const getUserRecordsSuccess = (
  payload: UserRecordsResponse
): GetUserRecordsSuccess => ({
  type: UserRecordsActionTypes.GetUserRecordsSuccess,
  payload
});

export const getUserRecordsFailed = (): GetUserRecordsFailed => ({
  type: UserRecordsActionTypes.GetUserRecordsFailed
});
