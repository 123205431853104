import { DitDialogLayout, DitTableHeaderColumn } from '@ditmac/is-ui';
import React from 'react';

import { ConnectionSourceRecord } from '../models';

interface ConnectionSourceDetailDialogProps {
  id?: string;
  item: ConnectionSourceRecord;
}

export const ConnectionSourceDetailDialog = ({
  item
}: ConnectionSourceDetailDialogProps) => {
  return (
    <DitDialogLayout>
      <div className="row mb-3">
        <div className="col">
          <div className="h1 text-primary">Record Detail</div>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-4">
          <DitTableHeaderColumn label="Name" sortable={false} />
        </div>
        <div className="col">
          <DitTableHeaderColumn label="Value" sortable={false} />
        </div>
      </div>

      {Object.keys(item).map((key, index) => (
        <div key={index} className="row my-1 font-weight-light">
          <div className="col-4">{key}</div>
          <div className="col">{item[key]}</div>
        </div>
      ))}
    </DitDialogLayout>
  );
};
