import * as builder from './ducks/builder';
import * as i18n from './ducks/i18n';

/**
 * Reexports
 */
export * from './utils/utils';
export * from './layout/LayoutContext';
export * from './layout/LayoutConfig';
export { default as LayoutConfig } from './layout/LayoutConfig';
export { default as LayoutInitializer } from './layout/LayoutInitializer';

/**
 * Ducks
 */

export const metronic = { i18n, builder };
