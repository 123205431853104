import { User } from '@app/users';
import { DitButton, DitInput } from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface MyAccountPersonalInformationProps {
  user: User;
  onSubmit: (user: Partial<User>) => void;
}

const MyAccountPersonalInformationValidation = Yup.object().shape({
  name: Yup.string().required(),
  phone: Yup.string().required(),
  company: Yup.string().required(),
  title: Yup.string().required()
  // employee_id: Yup.string().required()
});

export const MyAccountPersonalInformation = ({
  user,
  onSubmit
}: MyAccountPersonalInformationProps) => {
  const initialValues = {
    name: user.name,
    phone: user.phone,
    company: user.company,
    // employee_id: user.employee_id,
    title: user.title,
    username: user.username
  };

  const controls = [
    { label: 'Name', name: 'name' },
    { label: 'Phone', name: 'phone' },
    { label: 'Company', name: 'company' },
    // { label: 'Employee Id', name: 'employee_id' },
    { label: 'Title', name: 'title' }
  ];

  return (
    <Card className="py-3 px-4">
      <Formik
        initialValues={initialValues}
        validationSchema={MyAccountPersonalInformationValidation}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="row my-3">
            <div className="col">
              <h4 className="text-primary">Personal Information</h4>
            </div>
          </div>

          {controls.map((control, index) => (
            <div key={index} className="row py-1">
              <div className="col-3 pt-3">
                <span>{control.label}</span>
              </div>
              <div className="col-6">
                <Field
                  component={DitInput}
                  label={control.label}
                  hideLabel={true}
                  placeholder={control.label}
                  name={control.name}
                />
              </div>
            </div>
          ))}

          <div className="row py-2 mt-3">
            <div className="col-9 flex-right">
              <DitButton
                label="Cancel"
                className="text-primary border-primary bg-white mr-2"
              />
              <DitButton label="Save changes" color="primary" type="submit" />
            </div>
          </div>
          <div className="row py-3"></div>
        </Form>
      </Formik>
    </Card>
  );
};
