import { AccessManagment } from '@app/access-managment';
import { UserProfile } from '@app/user-profile';
import { UserRecords } from '@app/user-records';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserListContainer from './containers/user-list-container';

export const Users = () => {
  return (
    <Switch>
      <Route
        path="/spa/settings/users"
        exact={true}
        component={UserListContainer}
      ></Route>
      <Route
        path="/spa/settings/users/access-managment"
        exact={true}
        component={AccessManagment}
      ></Route>
      <Route
        path="/spa/settings/users/:id/records/all"
        component={UserRecords}
      ></Route>
      <Route path="/spa/settings/users/:id" component={UserProfile}></Route>
    </Switch>
  );
};
