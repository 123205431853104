import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ViewConfigurationDialog } from '../components';
import {
  ConnecitonSourceDetailViewPayload,
  ConnectionSourceDetailView,
  ConnectionSourceDetailViewsResponse
} from '../models';
import { ConnectionSourceDetailsService } from '../services';
import {
  createConnectionSourceDetailFields,
  createConnectionSourceDetailViewsFailed,
  createConnectionSourceDetailViewsSuccess,
  setCurrentConnectionSourceDetailView,
  updateConnectionSourceDetailFields,
  updateConnectionSourceDetailViewsFailed,
  updateConnectionSourceDetailViewsSuccess
} from '../state';

export const ViewconfigurationDialogContainer = ({
  dispatch,
  fieldOptions,
  loading,
  username,
  currentView,
  userHasViews,
  views,
  onDone
}) => {
  const { integrationId, moduleId } = useParams();
  const [configurationSaved, setConfigurationSaved] = useState(false);

  const handleSubmit = async (formValue: ConnectionSourceDetailView) => {
    const updatedViews: ConnectionSourceDetailView[] = [
      ...views.filter((view) => view.name !== formValue.name),
      formValue
    ];
    const payload: ConnecitonSourceDetailViewPayload = {
      integrationId,
      moduleId,
      username,
      views: updatedViews,
      currentView: formValue.name
    };
    const response: ConnectionSourceDetailViewsResponse = {
      views: updatedViews,
      currentView: formValue.name
    };
    if (userHasViews) {
      try {
        dispatch(updateConnectionSourceDetailFields(payload));
        await ConnectionSourceDetailsService.updateFields(payload);
        dispatch(updateConnectionSourceDetailViewsSuccess(response));
        setConfigurationSaved(true);
      } catch (e) {
        dispatch(updateConnectionSourceDetailViewsFailed());
      }
    } else {
      try {
        dispatch(createConnectionSourceDetailFields(payload));
        await ConnectionSourceDetailsService.updateFields(payload);
        dispatch(createConnectionSourceDetailViewsSuccess(response));
        setConfigurationSaved(true);
      } catch (e) {
        dispatch(createConnectionSourceDetailViewsFailed());
      }
    }
    dispatch(setCurrentConnectionSourceDetailView(formValue));
  };
  return (
    <ViewConfigurationDialog
      fieldOptions={fieldOptions}
      loading={loading}
      configurationSaved={configurationSaved}
      currentView={currentView}
      onDone={onDone}
      onSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  fieldOptions: state.connectionSourceDetail.fieldOptions,
  username: state.auth.user && state.auth.user['cognito:username'],
  loading: getLoadingElse(state),
  currentView: state.connectionSourceDetail.viewToEdit,
  userHasViews: state.connectionSourceDetail.userHasViews,
  views: state.connectionSourceDetail.views
});

export default connect(mapStateToProps)(ViewconfigurationDialogContainer);
