import './spa.scss';

import { MyAccount } from '@app/my-account';
import { MyConnections } from '@app/my-connections';
import { Reports } from '@app/reports';
import { Settings } from '@app/settings';
import { LayoutContextProvider, LayoutSplashScreen } from '@ditmac/metronic';
import Layout from '@ditmac/metronic/lib/layout/Layout';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

export const Spa = withRouter(({ history }) => {
  const menuConfig = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        {
          title: 'My Connections',
          root: true,
          icon: 'la-book',
          page: '/spa/my-connections'
        },
        {
          title: 'Settings',
          root: true,
          icon: 'la-user',
          submenu: [
            {
              title: 'Users',
              page: '/spa/settings/users',
              search: `breadcum=${JSON.stringify([
                { name: 'Users', route: '/spa/settings/users' }
              ])}`
            },
            // {
            //   title: 'Roles',
            //   page: '/spa/settings/roles',
            //   search: `breadcum=${JSON.stringify([
            //     { name: 'Roles', route: '/spa/settings/roles' }
            //   ])}`
            // },
            // {
            //   title: 'Account Preferences',
            //   page: '/spa/settings/account-preferences',
            //   search: `breadcum=${JSON.stringify([
            //     {
            //       name: 'Account Preferences',
            //       route: '/spa/settings/account-preferences'
            //     }
            //   ])}`
            // }
          ]
        },
        {
          title: 'Reports',
          root: true,
          icon: 'la-file-alt',
          page: '/spa/reports'
        }
      ]
    }
  };

  return (
    <div>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Layout menuConfig={menuConfig}>
          <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
              <Redirect exact from="/spa" to="/spa/my-connections" />
              <Route path="/spa/my-connections" component={MyConnections} />
              <Route path="/spa/settings" component={Settings} />
              <Route path="/spa/reports" component={Reports} />
              <Route path="/spa/my-account" component={MyAccount} />
            </Switch>
          </Suspense>
        </Layout>
      </LayoutContextProvider>
    </div>
  );
});
