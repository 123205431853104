import './table-header-column.scss';

import React, { useState } from 'react';

enum SortStatus {
  none = 'none',
  asc = 'asc',
  desc = 'desc'
}

interface DitTableHeaderColumnProps {
  label: string;
  sortable?: boolean;
}

export const DitTableHeaderColumn = ({
  label,
  sortable
}: DitTableHeaderColumnProps) => {
  const [status, setStatus] = useState(SortStatus.none);

  const onSetStatus = () => {
    if (sortable) {
      const statuses = {
        [SortStatus.none]: SortStatus.asc,
        [SortStatus.asc]: SortStatus.desc,
        [SortStatus.desc]: SortStatus.none
      };
      setStatus(statuses[status]);
    }
  };
  return (
    <button
      className="btn d-flex p-0 m-0 border-0"
      type="button"
      onClick={onSetStatus}
    >
      <span className="mr-1">{label}</span>
      {sortable && (
        <>
          <i
            className={`up-arrow las la-long-arrow-alt-up ${
              status === SortStatus.asc && 'text-primary'
            }`}
          ></i>
          <i
            className={`down-arrow las la-long-arrow-alt-down ${
              status === SortStatus.desc && 'text-primary'
            }`}
          ></i>
        </>
      )}
    </button>
  );
};
