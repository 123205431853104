import { AppState } from '@app/state';
import React from 'react';
import { connect } from 'react-redux';

import { RoleForm } from '../components';
import { Role } from '../models';
import { createRole } from '../state';

export interface RoleFormContainerProps {
  dispatch?: any;
  onCancel: () => void;
}

const RoleFormContainer = ({ dispatch, onCancel }: RoleFormContainerProps) => {
  const handleSubmit = (role: Role) => {
    dispatch(createRole(role));
  };
  return <RoleForm onCancel={onCancel} onSubmit={handleSubmit} />;
};

const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps)(RoleFormContainer);
