import { omit } from 'lodash';

import { DitQueryParams } from '../../core';

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE = 1;

export function buildQueryParams(
  queryParams?: Partial<DitQueryParams>
): Partial<DitQueryParams> {
  if (!queryParams) {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      page: DEFAULT_PAGE
    };
  }
  if (!queryParams.search) {
    queryParams = omit(queryParams, 'search');
  }

  return{
    ...queryParams,
    pageSize: queryParams.pageSize || DEFAULT_PAGE_SIZE,
    page: queryParams.page || DEFAULT_PAGE
  };
}
