import { AppState } from '@app/state';
import { User } from '@app/users';
import React from 'react';
import { connect } from 'react-redux';

import { MyAccountPersonalInformation } from '../components';
import { savePersonalInformation } from '../state';

const MyAccountPersonalInformationContainer = ({ dispatch, user }) => {
  const handleSubmit = (payload: Partial<User>) => {
    dispatch(savePersonalInformation(payload));
  };

  return (
    <>
      {user && (
        <MyAccountPersonalInformation user={user} onSubmit={handleSubmit} />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.myAccount.user
});

export default connect(mapStateToProps)(MyAccountPersonalInformationContainer);
