import { User } from '@app/users';
import { DitSearchbar, DitSwitch } from '@ditmac/is-ui';
import { Avatar, Card } from '@material-ui/core';
import React from 'react';

interface AccessManagmentListProps {
  list: User[];
}

export const AccessManagmentList = (props: AccessManagmentListProps) => {
  return (
    <Card>
      <div className="row p-4 rounded-top">
        <div className="col">
          <h4 className="text-primary m-0">Permissions</h4>
        </div>
      </div>

      <div className="row px-4 py-3 border-bottom">
        <div className="col-4 flex-left">
          <DitSearchbar className="border" placeholder="Search user..." />
        </div>
        <div className="col-3 offset-2 flex-center">
          <span>View</span>
        </div>
        <div className="col-3 flex-center">
          <span>Export</span>
        </div>
      </div>

      {props.list.map((user) => {
        return (
          <div key={user.username} className="row list-row px-4 border-bottom">
            <div className="col-6">
              <Avatar className="mr-2">
                {user.name?.charAt(0).toUpperCase()}
              </Avatar>
              {user.name}
            </div>
            <div className="col-3 flex-center">
              <DitSwitch />
            </div>
            <div className="col-3 flex-center">
              <DitSwitch />
            </div>
          </div>
        );
      })}
      {/* <DitTableFooter /> */}
    </Card>
  );
};
