import { ConnectionSourceDetailPayload } from '@app/connection-source-detail';

import { ConnectionSourceResponse, GetConnectionSourcesRequest } from '../models';
import { ConnectionSourcesService } from '../services';

export enum ConnectionSourcesActionTypes {
  GetConnectionSources = '[Connection sources] Get connection sources',
  GetConnectionSourcesSuccess = '[Connection sources] Get connection sources success',
  GetConnectionSourcesFailed = '[Connection sources] Get connection sources failed',
  StartDataExtraction = '[Connection sources] Start data extraction sources',
  StartDataExtractionSuccess = '[Connection sources] Start data extraction sources success',
  StartDataExtractionFailed = '[Connection sources] Start data extraction sources failed'
}

export interface GetConnectionSources {
  type: ConnectionSourcesActionTypes.GetConnectionSources;
  payload: GetConnectionSourcesRequest;
}
export interface GetConnectionSourcesSuccess {
  type: ConnectionSourcesActionTypes.GetConnectionSourcesSuccess;
  payload: ConnectionSourceResponse;
}
export interface GetConnectionSourcesFailed {
  type: ConnectionSourcesActionTypes.GetConnectionSourcesFailed;
}
export interface StartDataExtraction {
  type: ConnectionSourcesActionTypes.StartDataExtraction;
  payload: ConnectionSourceDetailPayload;
}
export interface StartDataExtractionSuccess {
  type: ConnectionSourcesActionTypes.StartDataExtractionSuccess;
  payload: string;
}
export interface StartDataExtractionFailed {
  type: ConnectionSourcesActionTypes.StartDataExtractionFailed;
}

export type ConnectionSourceActions =
  | GetConnectionSources
  | GetConnectionSourcesSuccess
  | GetConnectionSourcesFailed
  | StartDataExtraction
  | StartDataExtractionSuccess
  | StartDataExtractionFailed;

export const getConnectionSources = (payload: GetConnectionSourcesRequest) => async (dispatch) => {
  dispatch({
    type: ConnectionSourcesActionTypes.GetConnectionSources,
    payload
  });
  try {
    const response = await ConnectionSourcesService.getAll(payload);
    dispatch(getConnectionSourcesSuccess(response.data));
  } catch (e) {
    dispatch(getConnectionSourcesFailed());
  }
};

export const getConnectionSourcesSuccess = (
  payload: ConnectionSourceResponse
): GetConnectionSourcesSuccess => ({
  type: ConnectionSourcesActionTypes.GetConnectionSourcesSuccess,
  payload
});

export const getConnectionSourcesFailed = (): GetConnectionSourcesFailed => ({
  type: ConnectionSourcesActionTypes.GetConnectionSourcesFailed
});

export const startDataExtraction = (
  payload: ConnectionSourceDetailPayload
) => async (dispatch) => {
  dispatch({ type: ConnectionSourcesActionTypes.StartDataExtraction });
  const { integrationId, moduleId } = payload;
  try {
    await ConnectionSourcesService.startDataExtraction(integrationId, moduleId);
    dispatch(startDataExtractionSuccess(moduleId));
  } catch (e) {
    dispatch(startDataExtractionFailed());
  }
};

export const startDataExtractionSuccess = (
  payload: string
): StartDataExtractionSuccess => ({
  type: ConnectionSourcesActionTypes.StartDataExtractionSuccess,
  payload
});

export const startDataExtractionFailed = (): StartDataExtractionFailed => ({
  type: ConnectionSourcesActionTypes.StartDataExtractionFailed
});
