import './switch.scss';

import { createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React, { ChangeEvent, useEffect, useState } from 'react';

const AntSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: '#f8f9fd',
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#ffffff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#007aff',
          borderColor: '#007aff'
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      background: '#ffffff'
    },
    track: {
      border: '1px solid #E8EBF1',
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#E8EBF1'
    },
    checked: {}
  })
)(Switch);

interface DitSwitchProps {
  checked?: boolean;
  onToggle?: (active: boolean) => any;
}

export const DitSwitch = ({ checked, onToggle }: DitSwitchProps) => {
  const [state, setState] = useState(checked);

  useEffect(() => {
    setState(checked);
  }, [checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
    if (onToggle) {
      onToggle(event.target.checked);
    }
  };

  return <AntSwitch checked={state} onChange={handleChange} />;
};
