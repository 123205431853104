import './dialog-layout.scss';

import React from 'react';

interface DitDialogLayoutProps {
  size?: 'xs' | 'sm' | 'lg' | 'xl';
  children: any;
}

export const DitDialogLayout = ({ size, children }: DitDialogLayoutProps) => {
  return (
    <div className={`dialog-${size || 'lg'} container-fluid bg-white p-5`}>
      {children}
    </div>
  );
};
