import { SkeletonList } from '@app/skeletons';
import { DitQueryParams, Pagination } from '@ditmac/data';
import {
  BreadcumItem,
  DitButton,
  DitColumns,
  DitSearchbar,
  DitTableFooter,
  DitTableHeader
} from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import React from 'react';

import { Role } from '../models';
import { RoleRow } from './role-row';

interface RoleListProps {
  list: Role[];
  pagination: Pagination;
  loading: boolean;
  breadcum: BreadcumItem[];
  onFilterChange: (queryParams: DitQueryParams) => void;
  onCreate: () => void;
  onToggle: (role_id: string, enabled: boolean) => void;
}

export const RoleList = ({
  list,
  pagination,
  loading,
  breadcum,
  onFilterChange,
  onCreate,
  onToggle
}: RoleListProps) => {
  const columns: DitColumns = [
    {
      colSize: 3,
      label: 'Name'
    },
    {
      colSize: 3,
      label: 'Description'
    },
    {
      colSize: 3,
      label: 'Created at'
    },
    {
      colSize: 3,
      label: 'Actions',
      alignment: 'right'
    }
  ];
  return (
    <>
      {loading && !pagination ? (
        <SkeletonList />
      ) : (
        <Card>
          <div className="row mx-0 p-3">
            <div className="col">
              <h3 className="text-primary">Roles</h3>
            </div>
            <div className="col flex-right">
              <DitButton
                label="Add Role"
                color="primary"
                icon="la-plus"
                onClick={onCreate}
              />
            </div>
          </div>

          <div className="row mb-3 mx-0 px-3">
            <div className="col-4">
              <DitSearchbar onChange={onFilterChange} />
            </div>
          </div>

          <div className="row mx-0 px-3">
            <div className="col">
              <DitTableHeader columns={columns} />
            </div>
          </div>

          {list.map((role, index) => {
            return (
              <RoleRow
                key={index}
                role={role}
                breadcum={breadcum}
                onToggle={onToggle}
              />
            );
          })}

          <DitTableFooter
            pagination={pagination}
            onPaginationChange={onFilterChange}
          />
        </Card>
      )}
    </>
  );
};
