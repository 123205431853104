import {
  BreadcumItem,
  DitIconButton,
  DitSwitch,
  DitTooltip
} from '@ditmac/is-ui';
import Moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { Role } from '../models';

interface RoleRowProps {
  role: Role;
  breadcum: BreadcumItem[];
  onToggle: (role_id: string, enabled: boolean) => void;
}

export const RoleRow = ({ role, breadcum, onToggle }: RoleRowProps) => {
  const editRoute = `/spa/settings/roles/${role.role_id}`;
  const componentBreadcum: BreadcumItem[] = [
    ...breadcum,
    { name: role.name, route: editRoute }
  ];
  return (
    <div className="row list-row mx-0 px-3">
      <div className="col-3">{role.name}</div>
      <div className="col-3">{role.description}</div>
      <div className="col-3">
        {Moment(role.created_at).format('MMM D, YYYY')}
      </div>
      <div className="col-3 flex-right">
        <DitSwitch
          checked={role.enable}
          onToggle={(active) => onToggle(role.role_id, active)}
        />
        <DitTooltip tooltip="Edit role">
          <div>
            <Link
              to={{
                pathname: editRoute,
                search: `breadcum=${JSON.stringify(componentBreadcum)}`
              }}
              className="text-primary flex-left"
            >
              <DitIconButton
                className="px-2 py-2 text-primary edit-button ml-2"
                icon="la-edit"
              />
            </Link>
            <DitIconButton />
          </div>
        </DitTooltip>
      </div>
    </div>
  );
};
