import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import { DitQueryParams } from '@ditmac/data';
import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RoleList } from '../components';
import { getRoles, updateRole } from '../state';
import RoleFormContainer from './role-form-container';

const RoleListContainer = ({ dispatch, list, pagination, loading }) => {
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const handleCreate = () => {
    setOpen(true);
  };

  const handleFilterChange = (queryParams: DitQueryParams) => {
    dispatch(getRoles(queryParams));
  };

  const handleToggle = (role_id: string, enable: boolean) => {
    dispatch(updateRole({ role_id, enable }));
  };

  return (
    <>
      <RoleList
        list={list}
        pagination={pagination}
        loading={loading}
        breadcum={breadcum}
        onFilterChange={handleFilterChange}
        onCreate={handleCreate}
        onToggle={handleToggle}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <RoleFormContainer onCancel={() => setOpen(false)} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.roles.entities,
  pagination: state.roles.pagination,
  loading: getLoading(state)
});

export default connect(mapStateToProps)(RoleListContainer);
