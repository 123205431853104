import { ConnectionSourceLastExecutions } from '../enums';
import {
  ConnectionSourceActions,
  ConnectionSourcesActionTypes
} from './connection-sources.actions';
import { ConnectionSourcesState } from './connection-sources.state';

export const initialState: ConnectionSourcesState = {
  currentItem: null,
  entities: [],
  pagination: null
};

export function connectionSourcesReducer(
  state: ConnectionSourcesState = initialState,
  action: ConnectionSourceActions
): ConnectionSourcesState {
  switch (action.type) {
    case ConnectionSourcesActionTypes.GetConnectionSourcesSuccess:
      return {
        ...state,
        entities: action.payload.results,
        pagination: action.payload.pagination
      };
    case ConnectionSourcesActionTypes.StartDataExtractionSuccess:
      return {
        ...state,
        entities: state.entities.map((connectionSource) => {
          return connectionSource.module_id === action.payload
            ? {
                ...connectionSource,
                last_execution: ConnectionSourceLastExecutions.InProgress
              }
            : connectionSource;
        })
      };
    default:
      return state;
  }
}
