import { DitButton, DitInput } from '@ditmac/is-ui';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { SetPasswordWithCodePayload } from '../models';

interface RequestPasswordCodeFormProps {
  loading: boolean;
  onSubmit: (payload: SetPasswordWithCodePayload) => void;
}

function getValidationSchema(
  values: SetPasswordWithCodePayload
): Yup.ObjectSchema {
  return Yup.object().shape({
    code: Yup.string().required(),
    password: Yup.string().required().min(10),
    passwordConfirmation: Yup.string()
      .required()
      .min(10, 'Verify password field must be at least 10 characters')
      .oneOf(
        [values.password],
        'The "New password" and "Verify password" fields have different values`'
      )
  });
}

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
}

function validate(values) {
  const validationSchema = getValidationSchema(values);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

const initialValues: SetPasswordWithCodePayload = {
  code: '',
  password: '',
  passwordConfirmation: ''
};

export const RequestPasswordCodeForm = ({
  loading,
  onSubmit
}: RequestPasswordCodeFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="row mb-3">
          <div className="col">
            <span>
              A verification code was sent to your email, enter the code and the
              new password to set your password
            </span>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <Field component={DitInput} name="code" label="Code" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <Field
              component={DitInput}
              type="password"
              name="password"
              label="New Password"
              autoCompleteOff={true}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <Field
              component={DitInput}
              type="password"
              name="passwordConfirmation"
              label="Verify Password"
              autoCompleteOff={true}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <DitButton
              color="primary"
              label="Set new password"
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
