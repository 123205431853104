import { User } from '@app/users';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

export class MyAccountService {
  static get(username: string): Promise<AxiosResponse<User>> {
    return axios.get<User>(`${environment.apiUrl}/user/${username}`);
  }

  static update(payload: Partial<User>): Promise<AxiosResponse<User>> {
    return axios.put<User>(`${environment.apiUrl}/user`, payload);
  }
}
