import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RequestPasswordForm } from '../components';
import { RequestPasswordRequest, SetPasswordWithCodePayload } from '../models';
import {
  confirmForgotPassword,
  recoverPassword,
  resetPasswordRecoveryEmailSent
} from '../state';

const RequestPasswordFormContainer = ({
  dispatch,
  emailSent,
  loading,
  username
}) => {
  useEffect(() => {
    return () => {
      dispatch(resetPasswordRecoveryEmailSent());
    };
  }, [dispatch]);

  const handleSubmit = (payload: RequestPasswordRequest) => {
    dispatch(recoverPassword(payload.username));
  };

  const handleSubmitNewPassword = (value: SetPasswordWithCodePayload) => {
    dispatch(
      confirmForgotPassword({
        username,
        code: value.code,
        password: value.password
      })
    );
  };

  return (
    <RequestPasswordForm
      emailSent={emailSent}
      loading={loading}
      onSubmit={handleSubmit}
      onSubmitNewPassword={handleSubmitNewPassword}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  emailSent: state.auth.passwordRecoveryEmailSent,
  loading: getLoadingElse(state),
  username: state.auth.username
});

export default connect(mapStateToProps)(RequestPasswordFormContainer);
