import { displayToastr, ToastrSeverities } from '@app/core';
import { User } from '@app/users';

import { MyAccountService } from '../services';

export enum MyAccountActionTypes {
  GetMyAccount = '[My Account] Get my account',
  GetMyAccountSuccess = '[My Account] Get my account success',
  GetMyAccountFailed = '[My Account] Get my account failed',
  SavePersonalInformation = '[My Account] Save personal information',
  SavePersonalInformationSuccess = '[My Account] Save personal information success',
  SavePersonalInformationFailed = '[My Account] Save personal information failed'
}

export interface GetMyAccount {
  type: MyAccountActionTypes.GetMyAccount;
  payload: string;
}
export interface GetMyAccountSuccess {
  type: MyAccountActionTypes.GetMyAccountSuccess;
  payload: User;
}
export interface GetMyAccountFailed {
  type: MyAccountActionTypes.GetMyAccountFailed;
}
export interface SavePersonalInformation {
  type: MyAccountActionTypes.SavePersonalInformation;
  payload: Partial<User>;
}
export interface SavePersonalInformationSuccess {
  type: MyAccountActionTypes.SavePersonalInformationSuccess;
}
export interface SavePersonalInformationFailed {
  type: MyAccountActionTypes.SavePersonalInformationFailed;
}

export type MyAccountActions =
  | GetMyAccount
  | GetMyAccountSuccess
  | GetMyAccountFailed
  | SavePersonalInformation
  | SavePersonalInformationSuccess
  | SavePersonalInformationFailed;

export const getMyAccount = (payload: string) => async (dispatch) => {
  dispatch({ type: MyAccountActionTypes.GetMyAccount, payload });
  try {
    const response = await MyAccountService.get(payload);
    dispatch(getMyAccountSuccess(response.data));
  } catch (e) {
    dispatch(getMyAccountFailed());
  }
};

export const getMyAccountSuccess = (payload: User): GetMyAccountSuccess => ({
  type: MyAccountActionTypes.GetMyAccountSuccess,
  payload
});

export const getMyAccountFailed = (): GetMyAccountFailed => ({
  type: MyAccountActionTypes.GetMyAccountFailed
});

export const savePersonalInformation = (payload: Partial<User>) => async (
  dispatch
) => {
  dispatch({ type: MyAccountActionTypes.SavePersonalInformation, payload });
  try {
    await MyAccountService.update(payload);
    dispatch(
      displayToastr({
        message: 'Personal information saved',
        severity: ToastrSeverities.Success
      })
    );
    dispatch(savePersonalInformationSuccess());
  } catch (e) {
    dispatch(savePersonalInformationFailed());
  }
};

export const savePersonalInformationSuccess = (): SavePersonalInformationSuccess => ({
  type: MyAccountActionTypes.SavePersonalInformationSuccess
});

export const savePersonalInformationFailed = (): SavePersonalInformationFailed => ({
  type: MyAccountActionTypes.SavePersonalInformationFailed
});
