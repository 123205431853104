import './reports.scss';

import { DitButton, DitSelect, SelectOption } from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';

const fileOptions: SelectOption[] = [
  {
    label: 'MOC_Details.xlsx',
    value: 'assets/files/MOC_Details.xlsx'
  },
  {
    label: 'Incident_Details.pdf',
    value: 'assets/files/Incident_Details.pdf'
  }
];

export const Reports = () => {
  const initialValues = {
    startDate: null,
    endDate: null,
    report: fileOptions[0].value,
    location: null
  };

  const handleSubmit = (values) => {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = values.fileType = values.report;
    link.download = fileOptions.find(
      (option) => option.value === values.report
    ).label;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange }) => (
        <Form>
          <div className="row mb-5">
            <div className="col flex-left">
              <h5 className="mb-0 mr-2">Reports</h5>
              <i className="las la-file-alt"></i>
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              <Card className="p-3">
                <div className="row mb-3">
                  <div className="col">
                    <h3>Generate report</h3>
                  </div>
                </div>

                <div className="row my-1">
                  <div className="col">Start date</div>
                  <div className="col">End date</div>
                </div>

                <div className="row mt-2 mb-4">
                  <div className="col">
                    <Field
                      component={KeyboardDatePicker}
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      name="startDate"
                      format="MM/DD/yyyy"
                    />
                  </div>
                  <div className="col">
                    <Field
                      component={KeyboardDatePicker}
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      name="endDate"
                      format="MM/DD/yyyy"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">Report</div>
                  <div className="col">Location</div>
                </div>

                <div className="row mb-4">
                  <div className="col">
                    <Field
                      name="report"
                      component={DitSelect}
                      options={fileOptions}
                    />
                  </div>
                  <div className="col">
                    <DitSelect options={[]} />
                  </div>
                </div>

                <div className="row my-3">
                  <div className="col">
                    <DitButton
                      label="Run report"
                      color="primary"
                      type="submit"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
