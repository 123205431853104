import React from 'react';

import { DitTableHeaderColumn } from './table-header-column';

export interface DitColumn<T = any> {
  colSize?: number | 'auto';
  alignment?: 'left' | 'center' | 'right';
  label?: string;
  sortable?: boolean;
}

export type DitColumns<T = any> = DitColumn<T>[];

interface DitHeaderColumnsProps {
  columns: DitColumns;
}

export const DitTableHeader = ({ columns }: DitHeaderColumnsProps) => {
  return (
    <div className="row mb-2">
      {columns.map((column, index) => {
        return (
          <div
            key={index}
            className={`col-${column.colSize} flex-${
              column.alignment || 'left'
            }`}
          >
            <DitTableHeaderColumn
              label={column.label}
              sortable={column.sortable}
            />
          </div>
        );
      })}
    </div>
  );
};
