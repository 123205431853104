import './success-dialog.scss';

import React from 'react';

import { DitButton } from './button';

interface DitSuccessDialogProps {
  title: string;
  subtitle?: string;
  onDone?: any;
}

export const DitSuccessDialog = (props: DitSuccessDialogProps) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col flex-center">
          <i className="las la-check success-dialog-icon"></i>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col flex-center">
          <span>{props.title}</span>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col flex-center">
          <span className="text-secondary">{props.subtitle}</span>
        </div>
      </div>
      <div className="row">
        <div className="col flex-center">
          <DitButton
            label="Ok"
            color="primary"
            onClick={() => props.onDone()}
          />
        </div>
      </div>
    </>
  );
};
