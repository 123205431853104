/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DitButton,
  DitDialogLayout,
  DitError,
  DitIconButton,
  DitInput,
  DitSelect,
  DitSuccessDialog,
  DitTableHeader
} from '@ditmac/is-ui';
import { Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';

import {
  columns,
  emptyField,
  fieldTypesLabels,
  filterTypeOptions,
  ViewConfigurationDialogProps,
  ViewConfigurationDialogValidation
} from './view-configuration-dialog.constants';

export const ViewConfigurationDialog = ({
  fieldOptions,
  loading,
  configurationSaved,
  currentView,
  onDone,
  onSubmit
}: ViewConfigurationDialogProps) => {
  let availableOptions = fieldOptions;

  const handleValidate = (values) => {
    availableOptions = fieldOptions.map((option) => ({
      ...option,
      disabled: values.fields.some((value) => value.value === option.value)
    }));
  };

  const handleFieldChange = (
    value: string,
    index: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const type = fieldOptions.find((option) => option.value === value).type;
    setFieldValue(`fields[${index}].field_type`, type);
  };

  return (
    <DitDialogLayout>
      {configurationSaved ? (
        <DitSuccessDialog
          title={`It's done!`}
          subtitle="View configuration has been saved successfully"
          onDone={onDone}
        />
      ) : (
        <Formik
          initialValues={{
            name: currentView?.name,
            fields: currentView?.fields || [emptyField]
          }}
          validationSchema={ViewConfigurationDialogValidation}
          validate={handleValidate}
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="row mb-3">
                <div className="col">
                  <div className="h1 text-primary">Configure View</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <Field
                    component={DitInput}
                    name="name"
                    label="Template name"
                    placeholder="Template name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <DitTableHeader columns={columns} />
                </div>
              </div>
              <FieldArray name="fields">
                {({ push, remove }) => (
                  <>
                    {values.fields.map((value, index) => (
                      <div key={index} className="row">
                        <div className="col-4">
                          <Field
                            name={`fields[${index}].value`}
                            component={DitSelect}
                            options={availableOptions}
                            onChange={(value) =>
                              handleFieldChange(value, index, setFieldValue)
                            }
                          />
                        </div>
                        <div className="col-4 flex-left">
                          {fieldTypesLabels[values.fields[index].field_type]}
                        </div>
                        <div className="col-3">
                          <Field
                            name={`fields[${index}].filter_type`}
                            component={DitSelect}
                            options={filterTypeOptions[value.field_type]}
                          />
                        </div>
                        <div className="col-1 flex-right">
                          <DitIconButton
                            icon="la-trash-alt"
                            onClick={() => remove(index)}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="row mt-2">
                      <div className="col">
                        <a
                          className="text-primary"
                          onClick={() => push(emptyField)}
                        >
                          <i className="las la-plus mr-2"></i>
                          Add field
                        </a>
                      </div>
                    </div>

                    {typeof errors.fields === 'string' && (
                      <div className="row">
                        <div className="col">
                          <DitError label={String(errors.fields)} />
                        </div>
                      </div>
                    )}

                    <div className="row mt-4">
                      <div className="col flex-right">
                        <DitButton
                          loading={loading}
                          color="primary"
                          label="Save changes"
                          type="submit"
                        />
                      </div>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      )}
    </DitDialogLayout>
  );
};
