import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import { Card } from '@material-ui/core';
import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

import LanguageTimeZoneContainer from './containers/language-time-zone-container';

export const AccountPreferencesComponent = () => {
  const menuItems = [
    {
      title: 'Language and time zone',
      icon: 'la-compass',
      page: '/spa/settings/account-preferences/language-time-zone'
    }
  ];
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  return (
    <div className="row">
      <div className="col">
        <Card className="p-3">
          <div className="row mb-3">
            <div className="col text-primary">
              <h4>Account Preferences</h4>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <span>Control settings relatewd to your instance</span>
            </div>
          </div>

          {menuItems.map((menuItem, index) => {
            return (
              <div key={index} className="row">
                <div className="col">
                  <NavLink
                    to={{
                      pathname: menuItem.page
                    }}
                    className="p-3 w-100 flex-left"
                    activeClassName="bg-light text-primary rounded"
                  >
                    <i className={`las ${menuItem.icon}  mr-3`}></i>
                    <span> {menuItem.title}</span>
                  </NavLink>
                </div>
              </div>
            );
          })}
        </Card>
      </div>
      <div className="col">
        <Switch>
          <Route
            path="/spa/settings/account-preferences/language-time-zone"
            component={LanguageTimeZoneContainer}
            exact={true}
          ></Route>
          <Redirect
            from="/spa/settings/account-preferences"
            exact={true}
            to={{
              pathname: '/spa/settings/account-preferences/language-time-zone',
              search: `breadcum=${JSON.stringify(breadcum)}`
            }}
          />
        </Switch>
      </div>
    </div>
  );
};
