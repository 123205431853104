import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RoleDetailContainer from './containers/role-detail-container';
import RoleListContainer from './containers/role-list-container';

export const Roles = () => {
  return (
    <Switch>
      <Route
        path="/spa/settings/roles"
        component={RoleListContainer}
        exact={true}
      ></Route>
      <Route
        path="/spa/settings/roles/:id"
        component={RoleDetailContainer}
        exact={true}
      ></Route>
    </Switch>
  );
};
