import '../../../../../libs/metronic/src/lib/_assets/sass/pages/login/login-1.scss';
import './auth.scss';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RequestPasswordFormContainer from './containers/request-password-form-container';
import SignInFormContainer from './containers/sign-in-form-container';

export const Auth = () => {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root bg-light">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside bg-primary text-white w-50 w100">
              <div className="kt-grid__item flex-left">
                <img
                  className="kt-login__logo logo"
                  alt="Logo"
                  src={'assets/img/logo-white.svg'}
                />
                <h4 className="mb-0 ml-3 ditmac-title">Ditmac</h4>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle padding-105">
                  <h3 className="welcome-title mb-4">
                    IntegraSolve - The platform that connects your data needs
                  </h3>
                  <p className="welcome-message">
                    IntegraSolve helps organizations become more effective and
                    efficient by enabling seamless business processes. We
                    elevate your siloed data by connecting your employees,
                    clients, vendors, equipment, and making it available across
                    departments with your culture in mind.
                  </p>
                  <p className="welcome-message">
                    Our solutions help you place controls across your
                    organization to reduce risks, with technology that you can
                    interface with, or that runs behind the scenes.
                  </p>
                  <p className="welcome-message">
                    IntegraSolve is a robust combination of scalable performance
                    infrastructure, application interfaces, and a no-code
                    solution developer kit that helps you craft desktop or
                    mobile experiences.
                  </p>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright text-white">
                    © Ditmac, LLC Uses | Terms and Conditions
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper bg-light p-5">
              <Switch>
                <Route path="/auth/sign-in">
                  <SignInFormContainer />
                </Route>
                <Route path="/auth/request-password">
                  <RequestPasswordFormContainer />
                </Route>
                <Redirect from="/auth" exact={true} to="/auth/sign-in" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
