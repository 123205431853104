import { SekeletonCard } from '@app/skeletons';
import React from 'react';

import { Connection } from '../models';
import { ConnectionCard } from './connection-card';

interface ConnectionCardsProps {
  list: Connection[];
  loading: boolean;
}

export const ConnectionCards = ({ list, loading }: ConnectionCardsProps) => {
  return (
    <div className="row">
      {loading
        ? Array(9)
            .fill(9)
            .map((_, index) => (
              <div key={index} className="col-4 my-2">
                <SekeletonCard />
              </div>
            ))
        : list.map((connection, index) => (
            <div key={index} className="col-4 pl-0">
              <ConnectionCard item={connection} />
            </div>
          ))}
    </div>
  );
};
