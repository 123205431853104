import { AppState } from '@app/state';
import { getUser } from '@app/users/state/users.actions';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProfileCard } from '../components';

const UserProfileCardContainer = ({ currentItem, dispatch, queryParams }) => {
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  return <UserProfileCard item={currentItem} queryParams={queryParams} />;
};

const mapStateToProps = (state: AppState) => ({
  currentItem: state.users.currentItem,
  queryParams: state.router.location.search
});

export default connect(mapStateToProps)(UserProfileCardContainer);
