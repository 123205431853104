import { buildQueryParams, DitQueryParams } from '@ditmac/data';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import {
  ConnecitonSourceDetailViewPayload,
  ConnectionSourceDetailResponse,
  ConnectionSourceDetailViewsResponse,
  ExportConnectionSourceDetailResponse
} from '../models';

export class ConnectionSourceDetailsService {
  static getAll(
    integrationId: string,
    moduleId: string,
    queryParams?: Partial<DitQueryParams>
  ): Promise<AxiosResponse<ConnectionSourceDetailResponse>> {
    const params = buildQueryParams(queryParams);
    return axios.get<ConnectionSourceDetailResponse>(
      `${environment.apiUrl}/preview/${integrationId}/${moduleId}`,
      { params, timeout: 3600000 }
    );
  }

  static export(
    integrationId: string,
    moduleId: string
  ): Promise<AxiosResponse<ExportConnectionSourceDetailResponse[]>> {
    return axios.get<ExportConnectionSourceDetailResponse[]>(
      `${environment.apiUrl}/integration/${integrationId}/${moduleId}/export`
    );
  }

  static exportExcel(
    integrationId: string,
    moduleId: string,
    queryParams?: Partial<DitQueryParams>
  ): Promise<AxiosResponse<any>> {
    const params = buildQueryParams(queryParams);
    return axios.get(
      `${environment.apiUrl}/export/excel/${integrationId}/${moduleId}`,
      { params }
    );
  }

  static getFields(
    payload: ConnecitonSourceDetailViewPayload
  ): Promise<AxiosResponse<ConnectionSourceDetailViewsResponse>> {
    const { integrationId, moduleId } = payload;
    const id = `${integrationId}_${moduleId}`;
    return axios.get(`${environment.apiUrl}/user/data/${id}`);
  }

  static createFields(
    payload: ConnecitonSourceDetailViewPayload
  ): Promise<AxiosResponse<ConnectionSourceDetailViewsResponse>> {
    const { integrationId, moduleId, views, currentView } = payload;
    const id = `${integrationId}_${moduleId}`;
    return axios.post(`${environment.apiUrl}/user/data/${id}`, {
      views,
      currentView
    });
  }

  static updateFields(
    payload: ConnecitonSourceDetailViewPayload
  ): Promise<AxiosResponse<ConnectionSourceDetailViewsResponse>> {
    const { integrationId, moduleId, views, currentView } = payload;
    const id = `${integrationId}_${moduleId}`;
    return axios.put(`${environment.apiUrl}/user/data/${id}`, {
      views,
      currentView,
      preview_module_fields_id: id
    });
  }
}
