import React from 'react';

interface DitEmptyStateProps {
  message: string;
}

export const DitEmptyState = ({ message }: DitEmptyStateProps) => {
  return (
    <div className="row text-secondary p-5 rounded">
      <div className="col flex-center bg-light p-5">
        <h4>{message}</h4>
      </div>
    </div>
  );
};
