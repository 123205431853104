import { saveAs } from 'file-saver';

import {
  ConnecitonSourceDetailViewPayload,
  ConnectionSourceDetailPayload,
  ConnectionSourceDetailResponse,
  ConnectionSourceDetailView,
  ConnectionSourceDetailViewsResponse,
  ExportConnectionSourceDetailResponse
} from '../models';
import { ConnectionSourceDetailsService } from '../services/connection-source-details.service';
import {
  ConnectionSourceDetailsActionTypes,
  CreateConnectionSourceDetailViewFields,
  CreateConnectionSourceDetailViewFieldsFailed,
  CreateConnectionSourceDetailViewFieldsSuccess,
  ExportConnectionDetailsExcelFailed,
  ExportConnectionDetailsExcelSuccess,
  ExportConnectionDetailsFailed,
  ExportConnectionDetailsSuccess,
  GetConnectionSourceDetailsFailed,
  GetConnectionSourceDetailsSuccess,
  GetConnectionSourceDetailViewsFailed,
  GetConnectionSourceDetailViewsSuccess,
  ResetConnectionDetails,
  SetConnectionSourceDetailViewToEdit,
  SetCurrentConnectionSourceDetailView,
  UpdateConnectionSourceDetailView,
  UpdateConnectionSourceDetailViewFailed,
  UpdateConnectionSourceDetailViewSuccess
} from './connection-source-detail.action.types';

export const getConnectionSourceDetails = (
  payload: ConnectionSourceDetailPayload
) => async (dispatch) => {
  dispatch({
    type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetails,
    payload: payload
  });
  const { integrationId, moduleId, queryParams } = payload;
  try {
    const response = await ConnectionSourceDetailsService.getAll(
      integrationId,
      moduleId,
      queryParams
    );
    dispatch(getConnectionSourceDetailsSuccess(response.data));
  } catch (e) {
    console.error(e);
    dispatch(getConnectionSourceDetailsFailed());
  }
};

export const getConnectionSourceDetailsSuccess = (
  payload: ConnectionSourceDetailResponse
): GetConnectionSourceDetailsSuccess => ({
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailsSuccess,
  payload
});

export const getConnectionSourceDetailsFailed = (): GetConnectionSourceDetailsFailed => ({
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailsFailed
});

export const exportConnectionDetails = (
  payload: ConnectionSourceDetailPayload
) => async (dispatch) => {
  dispatch({
    type: ConnectionSourceDetailsActionTypes.ExportConnectionDetails,
    payload
  });
  const { integrationId, moduleId } = payload;
  try {
    const response = await ConnectionSourceDetailsService.export(
      integrationId,
      moduleId
    );
    dispatch(exportConnectionDetailsSuccess(response.data));
  } catch (e) {
    dispatch(exportConnectionDetailsFailed());
  }
};

export const exportConnectionDetailsSuccess = (
  payload: ExportConnectionSourceDetailResponse[]
): ExportConnectionDetailsSuccess => ({
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsSuccess,
  payload
});

export const exportConnectionDetailsFailed = (): ExportConnectionDetailsFailed => ({
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsFailed
});

export const exportConnectionDetailsExcel = (
  payload: ConnectionSourceDetailPayload
) => async (dispatch) => {
  dispatch({
    type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcel,
    payload
  });
  const { integrationId, moduleId, queryParams } = payload;
  try {
    const response = await ConnectionSourceDetailsService.exportExcel(
      integrationId,
      moduleId,
      queryParams
    );

    const data = response.data.data;
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
    });
    saveAs(blob, `${integrationId}_${moduleId}.xlsx`);
    dispatch(exportConnectionDetailsExcelSuccess());
  } catch (e) {
    console.error(e);
    dispatch(exportConnectionDetailsExcelFailed());
  }
};

export const exportConnectionDetailsExcelSuccess = (): ExportConnectionDetailsExcelSuccess => ({
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelSuccess
});

export const exportConnectionDetailsExcelFailed = (): ExportConnectionDetailsExcelFailed => ({
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelFailed
});

export const getConnectionSourceDetailFields = (
  payload: ConnecitonSourceDetailViewPayload
) => async (dispatch) => {
  dispatch({
    type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViews,
    payload
  });
  try {
    const response = await ConnectionSourceDetailsService.getFields(payload);
    dispatch(getConnectionSourceDetailFieldsSuccess(response.data));
  } catch (e) {
    dispatch(getConnectionSourceDetailFieldsFailed());
  }
};

export const getConnectionSourceDetailFieldsSuccess = (
  payload: any
): GetConnectionSourceDetailViewsSuccess => ({
  type:
    ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViewsSuccess,
  payload
});

export const getConnectionSourceDetailFieldsFailed = (): GetConnectionSourceDetailViewsFailed => ({
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViewsFailed
});

export const createConnectionSourceDetailFields = (
  payload: ConnecitonSourceDetailViewPayload
): CreateConnectionSourceDetailViewFields => ({
  type:
    ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFields,
  payload
});

export const createConnectionSourceDetailViewsSuccess = (
  payload: ConnectionSourceDetailViewsResponse
): CreateConnectionSourceDetailViewFieldsSuccess => ({
  type:
    ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFieldsSuccess,
  payload
});

export const createConnectionSourceDetailViewsFailed = (): CreateConnectionSourceDetailViewFieldsFailed => ({
  type:
    ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFieldsFailed
});

export const updateConnectionSourceDetailFields = (
  payload: ConnecitonSourceDetailViewPayload
): UpdateConnectionSourceDetailView => ({
  type: ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailView,
  payload
});

export const updateConnectionSourceDetailViewsSuccess = (
  payload: ConnectionSourceDetailViewsResponse
): UpdateConnectionSourceDetailViewSuccess => ({
  type:
    ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailViewSuccess,
  payload
});

export const updateConnectionSourceDetailViewsFailed = (): UpdateConnectionSourceDetailViewFailed => ({
  type:
    ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailViewFailed
});

export const setCurrentConnectionSourceDetailView = (
  payload: ConnectionSourceDetailView
): SetCurrentConnectionSourceDetailView => ({
  type: ConnectionSourceDetailsActionTypes.SetCurrentConnectionSourceDetailView,
  payload
});

export const setConnectionSourceDetailViewToEdit = (
  payload: ConnectionSourceDetailView
): SetConnectionSourceDetailViewToEdit => ({
  type: ConnectionSourceDetailsActionTypes.SetConnectionSourceDetailViewToEdit,
  payload
});

export const resetConnectionDetails = (): ResetConnectionDetails => ({
  type: ConnectionSourceDetailsActionTypes.ResetConnectionDetails
});
