import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import { getUserRecords } from '@app/user-records';
import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProfileRecordList } from '../components';

const UserProfileRecordListContainer = ({
  entities,
  dispatch,
  loading,
  queryParams
}) => {
  const { id } = useParams();
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];

  useEffect(() => {
    dispatch(getUserRecords({ username: id }));
  }, [dispatch, id]);

  return (
    <UserProfileRecordList
      list={entities}
      loading={loading}
      breadcum={breadcum}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  entities: state.userRecords.entities,
  loading: getLoading(state)
});

export default connect(mapStateToProps)(UserProfileRecordListContainer);
