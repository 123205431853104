import { getLoading, getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import { DitQueryParams } from '@ditmac/data';
import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConnectionSourcesList } from '../components/connection-sources-list';
import { getConnectionSources } from '../state';

const ConnectionSourcesListContainer = ({
  dispatch,
  list,
  loading,
  loadingElse,
  pagination
}) => {
  const { integrationId } = useParams();

  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];

  useEffect(() => {
    dispatch(getConnectionSources({ integrationId }));
  }, [dispatch, integrationId]);

  const handleFilterChange = (queryParams: Partial<DitQueryParams>) => {
    dispatch(getConnectionSources({ integrationId, queryParams }));
  };

  return (
    <ConnectionSourcesList
      breadcum={breadcum}
      connectionName={breadcum[0]?.name}
      connectionId={integrationId}
      list={list}
      loading={loading}
      loadingElse={loadingElse}
      pagination={pagination}
      onFilterChange={handleFilterChange}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: getLoading(state),
  loadingElse: getLoadingElse(state),
  list: state.connectionSources.entities,
  pagination: state.connectionSources.pagination
});

export default connect(mapStateToProps)(ConnectionSourcesListContainer);
