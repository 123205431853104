import { useQuery } from '@ditmac/utils';
import { BreadcumItem, DitBreadcum } from '@ditmac/is-ui';
import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import ConnectionSourceDetailListContainer from '../connection-source-detail/containers/connection-source-detail-list-container';
import ConnectionSourcesListContainer from '../connection-sources/containers/connection-sources-list-container';
import MyConnectionsListContainer from './containers/my-connections-list-container';

export const MyConnections = () => {
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];

  return (
    <>
      <div className="row mb-5">
        <div className="col flex-left">
          <Link to="/spa/my-connections">
            <h5 className="mb-0 mr-2">My Connections</h5>
          </Link>
          <i className="las la-book"></i>
        <DitBreadcum breadcum={breadcum}/>
        </div>
      </div>
      <Switch>
        <Route path="/spa/my-connections" exact={true}>
          <MyConnectionsListContainer />
        </Route>
        <Route path="/spa/my-connections/:integrationId" exact={true}>
          <ConnectionSourcesListContainer />
        </Route>
        <Route path="/spa/my-connections/:integrationId/:moduleId">
          <ConnectionSourceDetailListContainer />
        </Route>
      </Switch>
    </>
  );
};
