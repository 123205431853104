import { AppState } from '@app/state';
import React from 'react';
import { connect } from 'react-redux';

import { AccessManagmentList } from '../components';

const AccessManagmentListContainer = (props) => {
  return <AccessManagmentList list={props.entities} />;
};

const mapStateToProps = (state: AppState) => ({
  entities: state.users.entities,
});

export default connect(mapStateToProps)(AccessManagmentListContainer);
