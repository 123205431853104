import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { ChangePasswordRequest } from '../models/change-password-request.model';

export class UserProfileService {
  static changePassword(
    payload: ChangePasswordRequest
  ): Promise<AxiosResponse<any>> {
    return axios.put(`${environment.apiUrl}/user/change-password`, payload);
  }
}
