import React from 'react';
import { Link } from 'react-router-dom';

export interface BreadcumItem {
  name: string;
  route: string;
}

interface DitBreadcumProps {
  breadcum: BreadcumItem[];
}

export const DitBreadcum = ({ breadcum }: DitBreadcumProps) => {
  return (
    <div className="flex-left">
      {breadcum.map((breadcumItem, index) => {
        return (
          <Link
            key={breadcumItem.name}
            to={`${breadcumItem.route}?breadcum=${JSON.stringify(
              breadcum.slice(0, index + 1)
            )}`}
            className="flex-left text-secondary"
          >
            <span className="dot mx-2"></span>
            <h5 className="mb-0">{breadcumItem.name}</h5>
          </Link>
        );
      })}
    </div>
  );
};
