import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { MyAccountUserCard } from '../components';
import { getMyAccount } from '../state';

export const MyAccountUserCardContainer = ({
  dispatch,
  username,
  user,
  loading
}) => {
  useEffect(() => {
    dispatch(getMyAccount(username));
  }, [dispatch, username]);

  return <MyAccountUserCard user={user} loading={loading} />;
};

const mapStateToProps = (state: AppState) => ({
  user: state.myAccount.user,
  username: state.auth.user['cognito:username'],
  loading: getLoading(state)
});

export default connect(mapStateToProps)(MyAccountUserCardContainer);
