import {
  UserRecordsActions,
  UserRecordsActionTypes
} from './user-records.actions';
import { UserRecordsState } from './user-records.state';

export const initialState: UserRecordsState = {
  entities: [],
  pagination: null,
  queryParams: {}
};

export function userRecordsReducer(
  state: UserRecordsState = initialState,
  action: UserRecordsActions
): UserRecordsState {
  switch (action.type) {
    case UserRecordsActionTypes.GetUserRecords:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload.queryParams
        }
      };
    case UserRecordsActionTypes.GetUserRecordsSuccess:
      return {
        ...state,
        entities: action.payload.results,
        pagination: action.payload.pagination
      };
    default:
      return state;
  }
}
