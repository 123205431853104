import { ConnectionSourceDetailField } from '@app/connection-source-detail';
import { FieldTypes, FilterTypes } from '@app/connection-source-detail/enums';
import { ConnectionSourceDetailView } from '@app/connection-source-detail/models';
import { DitColumns, SelectOption } from '@ditmac/is-ui';
import * as Yup from 'yup';

export interface ViewConfigurationDialogProps {
  fieldOptions: SelectOption[];
  loading: boolean;
  configurationSaved: boolean;
  currentView: ConnectionSourceDetailView;
  onDone: () => void;
  onSubmit: (value: any) => void;
}

export const ViewConfigurationDialogValidation = Yup.object().shape({
  fields: Yup.array()
    .min(1, 'You must add at least one field')
    .of(
      Yup.object().shape({
        value: Yup.string().required('Field name is required'),
        field_type: Yup.string().required('Field type is required'),
        filter_type: Yup.string().required('Filter type is required')
      })
    )
});

export const columns: DitColumns = [
  {
    colSize: 4,
    label: 'Field name'
  },
  {
    colSize: 4,
    label: 'Field type'
  },
  {
    colSize: 3,
    label: 'Filter type'
  },
  {
    colSize: 1,
    label: 'Actions'
  }
];

export const emptyField: ConnectionSourceDetailField = {
  value: null,
  field_type: null,
  filter_type: null
};

export const fieldTypesLabels: Record<FieldTypes, string> = {
  [FieldTypes.String]: 'String',
  [FieldTypes.Number]: 'Number',
  [FieldTypes.Boolean]: 'Boolean',
  [FieldTypes.Date]: 'Date'
};

export const fieldTypeOptions: SelectOption[] = [
  { label: 'String', value: FieldTypes.String },
  { label: 'Number', value: FieldTypes.Number },
  { label: 'Boolean', value: FieldTypes.Boolean }
];

export const stringFilterTypeOptions: SelectOption[] = [
  { label: 'Contains', value: FilterTypes.Contains },
  { label: 'Not contains', value: FilterTypes.NotContains },
  { label: 'Begins with', value: FilterTypes.BeginsWith }
];

export const numberFilterTypeOptions: SelectOption[] = [
  { label: 'Equal', value: FilterTypes.Equal },
  { label: 'Not equal', value: FilterTypes.NotEqual },
  { label: 'Less than or equal', value: FilterTypes.LessThanOrEqual },
  { label: 'LessThan', value: FilterTypes.LessThan },
  { label: 'Greater than or equal', value: FilterTypes.GreaterThanOrEqual },
  { label: 'Greater than', value: FilterTypes.GreaterThan }
];

export const booleanFilterTypeOptions: SelectOption[] = [
  { label: 'Exists', value: FilterTypes.Exists },
  { label: 'Not exists', value: FilterTypes.NotExists }
];

export const filterTypeOptions: Record<FieldTypes, SelectOption[]> = {
  [FieldTypes.String]: stringFilterTypeOptions,
  [FieldTypes.Number]: numberFilterTypeOptions,
  [FieldTypes.Boolean]: booleanFilterTypeOptions,
  [FieldTypes.Date]: stringFilterTypeOptions
};
