import { displayToastr, ToastrSeverities } from '@app/core';
import { Dispatch } from 'redux';

import { ChangePasswordRequest } from '../models/change-password-request.model';
import { UserProfileService } from '../services/user-profile.service';

export enum UserProfileActionTypes {
  ChangePassword = '[User Profile] Change password',
  ChangePasswordSuccess = '[User Profile] Change password success',
  ChangePasswordFailed = '[User Profile] Change password failed',
  SetInvalidPassword = '[User Profile] Set invalid password'
}
export interface ChangePassword {
  type: UserProfileActionTypes.ChangePassword;
}
export interface ChangePasswordSuccess {
  type: UserProfileActionTypes.ChangePasswordSuccess;
}
export interface ChangePasswordFailed {
  type: UserProfileActionTypes.ChangePasswordFailed;
}
export interface SetInvalidPassword {
  type: UserProfileActionTypes.SetInvalidPassword;
  payload: boolean;
}

export type UserProfileActions =
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailed
  | SetInvalidPassword;

export const changePassword = (payload: ChangePasswordRequest) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: UserProfileActionTypes.ChangePassword, payload });
  try {
    await UserProfileService.changePassword(payload);
    dispatch(changePasswordSuccess()(dispatch));
  } catch (e) {
    dispatch(changePasswordFailed());
  }
};

export const changePasswordSuccess = () => (
  dispatch
): ChangePasswordSuccess => {
  dispatch(
    displayToastr({
      severity: ToastrSeverities.Success,
      message: 'The password was changed successfully!'
    })
  );
  return { type: UserProfileActionTypes.ChangePasswordSuccess };
};

export const changePasswordFailed = (): ChangePasswordFailed => ({
  type: UserProfileActionTypes.ChangePasswordFailed
});

export const setInvalidPassword = (payload: boolean): SetInvalidPassword => ({
  type: UserProfileActionTypes.SetInvalidPassword,
  payload
});
