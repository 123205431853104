import './searchbar.scss';

import { DitQueryParams } from '@ditmac/data';
import InputBase from '@material-ui/core/InputBase';
import React, { useCallback } from 'react';

interface DitSearchbarProps {
  className?: string;
  placeholder?: string;
  hideIcon?: boolean;
  onChange?: (value: Partial<DitQueryParams>) => any;
}

const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

export const DitSearchbar = ({
  className,
  placeholder,
  hideIcon,
  onChange
}: DitSearchbarProps) => {
  const debounceCallback = useCallback(
    debounce((search) => {
      onChange({ search });
    }, 500),
    []
  );

  const handleChange = (event) => {
    if (onChange) {
      debounceCallback(event.target.value);
    }
  };
  return (
    <div
      className={`${className || ''} search ${
        hideIcon ? '' : 'search-with-icon'
      } border rounded`}
    >
      {!hideIcon && (
        <div className="search-icon">
          <i className="las la-search"></i>
        </div>
      )}
      <InputBase
        className="w-100"
        placeholder={placeholder || 'Search...'}
        onChange={handleChange}
      />
    </div>
  );
};
