import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import { AccountPreferences, PreferenceOptions } from '../models';

export class AccountPreferencesService {
  static getAccountPreferences(): Promise<AxiosResponse<AccountPreferences>> {
    return axios.get<AccountPreferences>(
      `${environment.apiUrl}/account-preferences`
    );
  }

  static createAccountPreferences(
    payload: AccountPreferences
  ): Promise<AxiosResponse<AccountPreferences>> {
    return axios.post<AccountPreferences>(
      `${environment.apiUrl}/account-preferences`,
      payload
    );
  }

  static updateAccountPreferences(
    payload: AccountPreferences
  ): Promise<AxiosResponse<AccountPreferences>> {
    return axios.put<AccountPreferences>(
      `${environment.apiUrl}/account-preferences`,
      payload
    );
  }

  static getPreferenceOptions(): Promise<AxiosResponse<PreferenceOptions>> {
    return axios.get<PreferenceOptions>(`${environment.apiUrl}/preferences`);
  }
}
