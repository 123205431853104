import {
  ConnecitonSourceDetailViewPayload,
  ConnectionSourceDetailPayload,
  ConnectionSourceDetailResponse,
  ConnectionSourceDetailView,
  ConnectionSourceDetailViewsResponse,
  ExportConnectionSourceDetailResponse
} from '../models';

export enum ConnectionSourceDetailsActionTypes {
  GetConnectionSourceDetails = '[Connection details] Get connection source details',
  GetConnectionSourceDetailsSuccess = '[Connection details] Get connection source details success',
  GetConnectionSourceDetailsFailed = '[Connection details] Get connection source details failed',
  ExportConnectionDetails = '[Connection details] Export connection details',
  ExportConnectionDetailsSuccess = '[Connection details] Export connection details success',
  ExportConnectionDetailsFailed = '[Connection details] Export connection details failed',
  ExportConnectionDetailsExcel = '[Connection details] Export connection details excel',
  ExportConnectionDetailsExcelSuccess = '[Connection details] Export connection details excel success',
  ExportConnectionDetailsExcelFailed = '[Connection details] Export connection details excel failed',
  GetConnectionSourceDetailViews = '[Connection details] Get connection source detail views',
  GetConnectionSourceDetailViewsSuccess = '[Connection details] Get connection source detail views success',
  GetConnectionSourceDetailViewsFailed = '[Connection details] Get connection source detail views failed',
  CreateConnectionSourceDetailViewFields = '[Connection details] Create connection source detail view',
  CreateConnectionSourceDetailViewFieldsSuccess = '[Connection details] Create connection source detail view success',
  CreateConnectionSourceDetailViewFieldsFailed = '[Connection details] Create connection source detail view failed',
  UpdateConnectionSourceDetailView = '[Connection details] Update connection source detail view',
  UpdateConnectionSourceDetailViewSuccess = '[Connection details] Update connection source detail view success',
  UpdateConnectionSourceDetailViewFailed = '[Connection details] Update connection source detail view failed',
  SetCurrentConnectionSourceDetailView = '[Connection details] Set current connection source detail view',
  SetConnectionSourceDetailViewToEdit = '[Connection details] Set connection source detail view to edit',
  ResetConnectionDetails = '[Connection details] reset connection details'
}

export interface GetConnectionSourceDetails {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetails;
  payload: ConnectionSourceDetailPayload;
}
export interface GetConnectionSourceDetailsSuccess {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailsSuccess;
  payload: ConnectionSourceDetailResponse;
}
export interface GetConnectionSourceDetailsFailed {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailsFailed;
}
export interface ExportConnectionDetails {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetails;
  payload: ConnectionSourceDetailPayload;
}
export interface ExportConnectionDetailsSuccess {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsSuccess;
  payload: ExportConnectionSourceDetailResponse[];
}
export interface ExportConnectionDetailsFailed {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsFailed;
}
export interface ExportConnectionDetailsExcel {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcel;
  payload: ConnectionSourceDetailPayload;
}
export interface ExportConnectionDetailsExcelSuccess {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelSuccess;
}
export interface ExportConnectionDetailsExcelFailed {
  type: ConnectionSourceDetailsActionTypes.ExportConnectionDetailsExcelFailed;
}
export interface GetConnectionSourceDetailViews {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViews;
  payload: ConnecitonSourceDetailViewPayload;
}
export interface GetConnectionSourceDetailViewsSuccess {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViewsSuccess;
  payload: ConnectionSourceDetailViewsResponse;
}
export interface GetConnectionSourceDetailViewsFailed {
  type: ConnectionSourceDetailsActionTypes.GetConnectionSourceDetailViewsFailed;
}
export interface CreateConnectionSourceDetailViewFields {
  type: ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFields;
  payload: ConnecitonSourceDetailViewPayload;
}
export interface CreateConnectionSourceDetailViewFieldsSuccess {
  type: ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFieldsSuccess;
  payload: ConnectionSourceDetailViewsResponse;
}
export interface CreateConnectionSourceDetailViewFieldsFailed {
  type: ConnectionSourceDetailsActionTypes.CreateConnectionSourceDetailViewFieldsFailed;
}
export interface UpdateConnectionSourceDetailView {
  type: ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailView;
  payload: ConnecitonSourceDetailViewPayload;
}
export interface UpdateConnectionSourceDetailViewSuccess {
  type: ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailViewSuccess;
  payload: ConnectionSourceDetailViewsResponse;
}
export interface UpdateConnectionSourceDetailViewFailed {
  type: ConnectionSourceDetailsActionTypes.UpdateConnectionSourceDetailViewFailed;
}
export interface SetCurrentConnectionSourceDetailView {
  type: ConnectionSourceDetailsActionTypes.SetCurrentConnectionSourceDetailView;
  payload: ConnectionSourceDetailView;
}
export interface SetConnectionSourceDetailViewToEdit {
  type: ConnectionSourceDetailsActionTypes.SetConnectionSourceDetailViewToEdit;
  payload: ConnectionSourceDetailView;
}
export interface ResetConnectionDetails {
  type: ConnectionSourceDetailsActionTypes.ResetConnectionDetails;
}

export type ConnectionSourceDetailActions =
  | GetConnectionSourceDetails
  | GetConnectionSourceDetailsSuccess
  | GetConnectionSourceDetailsFailed
  | ExportConnectionDetails
  | ExportConnectionDetailsSuccess
  | ExportConnectionDetailsFailed
  | ExportConnectionDetailsExcel
  | ExportConnectionDetailsExcelSuccess
  | ExportConnectionDetailsExcelFailed
  | GetConnectionSourceDetailViews
  | GetConnectionSourceDetailViewsSuccess
  | GetConnectionSourceDetailViewsFailed
  | CreateConnectionSourceDetailViewFields
  | CreateConnectionSourceDetailViewFieldsSuccess
  | CreateConnectionSourceDetailViewFieldsFailed
  | UpdateConnectionSourceDetailView
  | UpdateConnectionSourceDetailViewSuccess
  | UpdateConnectionSourceDetailViewFailed
  | SetCurrentConnectionSourceDetailView
  | SetConnectionSourceDetailViewToEdit
  | ResetConnectionDetails;
