import { displayToastr, ToastrSeverities } from '@app/core';

import { AccountPreferences, PreferenceOptions } from '../models';
import { AccountPreferencesService } from '../services';

export enum AccountPreferencesActionTypes {
  GetAccountPreferences = '[Account Preferences] Get account preferences',
  GetAccountPreferencesSuccess = '[Account Preferences] Get account preferences success',
  GetAccountPreferencesFailed = '[Account Preferences] Get account preferences failed',
  CreateAccountPreferences = '[Account Preferences] Create account preferences',
  CreateAccountPreferencesSuccess = '[Account Preferences] Create account preferences success',
  CreateAccountPreferencesFailed = '[Account Preferences] Create account preferences failed',
  UpdateAccountPreferences = '[Account Preferences] Update account preferences',
  UpdateAccountPreferencesSuccess = '[Account Preferences] Update account preferences success',
  UpdateAccountPreferencesFailed = '[Account Preferences] Update account preferences failed',
  GetPreferenceOptions = '[Account Preferences] Get preference optionns',
  GetPreferenceOptionsSuccess = '[Account Preferences] Get preference optionns success',
  GetPreferenceOptionsFailed = '[Account Preferences] Get preference optionns failed'
}

export interface GetAccountPreferences {
  type: AccountPreferencesActionTypes.GetAccountPreferences;
}
export interface GetAccountPreferencesSuccess {
  type: AccountPreferencesActionTypes.GetAccountPreferencesSuccess;
  payload: AccountPreferences;
}
export interface GetAccountPreferencesFailed {
  type: AccountPreferencesActionTypes.GetAccountPreferencesFailed;
}
export interface CreateAccountPreferences {
  type: AccountPreferencesActionTypes.CreateAccountPreferences;
  payload: AccountPreferences;
}
export interface CreateAccountPreferencesSuccess {
  type: AccountPreferencesActionTypes.CreateAccountPreferencesSuccess;
  payload: AccountPreferences;
}
export interface CreateAccountPreferencesFailed {
  type: AccountPreferencesActionTypes.CreateAccountPreferencesFailed;
}
export interface UpdateAccountPreferences {
  type: AccountPreferencesActionTypes.UpdateAccountPreferences;
  payload: AccountPreferences;
}
export interface UpdateAccountPreferencesSuccess {
  type: AccountPreferencesActionTypes.UpdateAccountPreferencesSuccess;
  payload: AccountPreferences;
}
export interface UpdateAccountPreferencesFailed {
  type: AccountPreferencesActionTypes.UpdateAccountPreferencesFailed;
}
export interface GetPreferenceOptions {
  type: AccountPreferencesActionTypes.GetPreferenceOptions;
}
export interface GetPreferenceOptionsSuccess {
  type: AccountPreferencesActionTypes.GetPreferenceOptionsSuccess;
  payload: PreferenceOptions;
}
export interface GetPreferenceOptionsFailed {
  type: AccountPreferencesActionTypes.GetPreferenceOptionsFailed;
}

export type AccountPreferencesActions =
  | GetAccountPreferences
  | GetAccountPreferencesSuccess
  | GetAccountPreferencesFailed
  | CreateAccountPreferences
  | CreateAccountPreferencesSuccess
  | CreateAccountPreferencesFailed
  | UpdateAccountPreferences
  | UpdateAccountPreferencesSuccess
  | UpdateAccountPreferencesFailed
  | GetPreferenceOptions
  | GetPreferenceOptionsSuccess
  | GetPreferenceOptionsFailed;

export const getAccountPreferences = () => async (dispatch) => {
  dispatch({ type: AccountPreferencesActionTypes.GetAccountPreferences });
  try {
    const response = await AccountPreferencesService.getAccountPreferences();
    dispatch(getAccountPreferencesSuccess(response.data));
  } catch (e) {
    dispatch(getAccountPreferencesFailed());
  }
};

export const getAccountPreferencesSuccess = (
  payload: AccountPreferences
): GetAccountPreferencesSuccess => ({
  type: AccountPreferencesActionTypes.GetAccountPreferencesSuccess,
  payload
});

export const getAccountPreferencesFailed = (): GetAccountPreferencesFailed => ({
  type: AccountPreferencesActionTypes.GetAccountPreferencesFailed
});

export const createAccountPreferences = (payload: AccountPreferences) => async (
  dispatch
) => {
  dispatch({
    type: AccountPreferencesActionTypes.CreateAccountPreferences,
    payload
  });
  try {
    await AccountPreferencesService.createAccountPreferences(payload);
    dispatch(createAccountPreferencesSuccess(payload));
    dispatch(
      displayToastr({
        message: 'Account preferences saved',
        severity: ToastrSeverities.Success
      })
    );
  } catch (e) {
    dispatch(createAccountPreferencesFailed());
  }
};

export const createAccountPreferencesSuccess = (
  payload: AccountPreferences
): CreateAccountPreferencesSuccess => ({
  type: AccountPreferencesActionTypes.CreateAccountPreferencesSuccess,
  payload
});

export const createAccountPreferencesFailed = (): CreateAccountPreferencesFailed => ({
  type: AccountPreferencesActionTypes.CreateAccountPreferencesFailed
});

export const updateAccountPreferences = (payload: AccountPreferences) => async (
  dispatch
) => {
  dispatch({
    type: AccountPreferencesActionTypes.UpdateAccountPreferences,
    payload
  });
  try {
    await AccountPreferencesService.updateAccountPreferences(payload);
    dispatch(updateAccountPreferencesSuccess(payload));
    dispatch(
      displayToastr({
        message: 'Account preferences saved',
        severity: ToastrSeverities.Success
      })
    );
  } catch (e) {
    dispatch(updateAccountPreferencesFailed());
  }
};

export const updateAccountPreferencesSuccess = (
  payload: AccountPreferences
): UpdateAccountPreferencesSuccess => ({
  type: AccountPreferencesActionTypes.UpdateAccountPreferencesSuccess,
  payload
});

export const updateAccountPreferencesFailed = (): UpdateAccountPreferencesFailed => ({
  type: AccountPreferencesActionTypes.UpdateAccountPreferencesFailed
});

export const getPreferenceOptions = () => async (dispatch) => {
  dispatch({ type: AccountPreferencesActionTypes.GetPreferenceOptions });
  try {
    const response = await AccountPreferencesService.getPreferenceOptions();
    dispatch(getPreferenceOptionsSuccess(response.data));
  } catch (e) {
    dispatch(getPreferenceOptionsFailed());
  }
};

export const getPreferenceOptionsSuccess = (
  payload: PreferenceOptions
): GetPreferenceOptionsSuccess => ({
  type: AccountPreferencesActionTypes.GetPreferenceOptionsSuccess,
  payload
});

export const getPreferenceOptionsFailed = (): GetPreferenceOptionsFailed => ({
  type: AccountPreferencesActionTypes.GetPreferenceOptionsFailed
});
