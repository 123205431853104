import { DitButton, DitInput } from '@ditmac/is-ui';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { RequestPasswordRequest } from '../models';

const requestPasswordUsernameFormValidation = Yup.object().shape({
  username: Yup.string().required()
});

interface RequestPasswordUsernameFormProps {
  loading: boolean;
  onSubmit: (payload: RequestPasswordRequest) => void;
}

export const RequestPasswordUsernameForm = ({
  loading,
  onSubmit
}: RequestPasswordUsernameFormProps) => {
  return (
    <Formik
      initialValues={{ username: '' }}
      validationSchema={requestPasswordUsernameFormValidation}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="row mb-3">
          <div className="col">
            <h2 className="text-black font-weight-bold">
              Forgot your password?
            </h2>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <span>
              Do not worry, just enter you username below, we will send you
              instructions on how to reset your password
            </span>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <Field
              component={DitInput}
              placeholder="Your username"
              name="username"
              label="username"
              hideLabel={true}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <DitButton
              color="primary"
              label="Request password reset"
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
