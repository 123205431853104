import { buildQueryParams } from '@ditmac/data';
import { environment } from '@env/environment';
import axios, { AxiosResponse } from 'axios';

import {
  ConnectionSource,
  ConnectionSourceResponse,
  GetConnectionSourcesRequest
} from '../models';

export class ConnectionSourcesService {
  static getAll(
    payload: GetConnectionSourcesRequest
  ): Promise<AxiosResponse<ConnectionSourceResponse>> {
    const { integrationId, queryParams } = payload;
    const params = buildQueryParams(queryParams);
    return axios.get<ConnectionSourceResponse>(
      `${environment.apiUrl}/integration/${integrationId}/list`,
      { params }
    );
  }

  static startDataExtraction(
    integrationId: string,
    moduleId: string
  ): Promise<AxiosResponse<ConnectionSource[]>> {
    return axios.put<ConnectionSource[]>(
      `${environment.apiUrl}/integration/${integrationId}/${moduleId}/extract`
    );
  }
}
