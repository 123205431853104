import { SkeletonList } from '@app/skeletons';
import { DitQueryParams, Pagination } from '@ditmac/data';
import {
  DitButton,
  DitEmptyState,
  DitIconButton,
  DitSearchbar,
  DitSelect,
  DitTableFooter,
  DitTableHeaderColumn,
  DitTextTruncateTooltip
} from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import Moment from 'moment';
import React from 'react';

import {
  ConnectionSourceDetailField,
  ConnectionSourceDetailView,
  ConnectionSourceRecord
} from '../models';

interface ConnectionSourceDetailListProps {
  connectionName: string;
  connectionSourceName: string;
  list: ConnectionSourceRecord[];
  loading: boolean;
  loadingExport: boolean;
  loadingExportExcel: boolean;
  pagination: Pagination;
  fieldConfiguration: ConnectionSourceDetailField[];
  views: ConnectionSourceDetailView[];
  currentView: string;
  onFilterChange: (queryParams: Partial<DitQueryParams>) => void;
  onExport: () => void;
  onExportExcel: () => void;
  onShowDetail: (record: ConnectionSourceRecord) => void;
  onConfigureView: (view?: ConnectionSourceDetailView) => void;
  onViewChange(view: ConnectionSourceDetailView);
}

export const ConnectionSourceDetailList = ({
  connectionName,
  connectionSourceName,
  list,
  loading,
  loadingExport,
  loadingExportExcel,
  pagination,
  onFilterChange,
  fieldConfiguration,
  views,
  currentView,
  onExport,
  onShowDetail,
  onConfigureView,
  onExportExcel,
  onViewChange
}: ConnectionSourceDetailListProps) => {
  const handleColumnChange = (field: string, value: any): void => {
    const queryParams: Partial<DitQueryParams> = { [field]: value };
    onFilterChange(queryParams);
  };

  const handleViewSelect = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ): void => {
    setFieldValue(value, 'view');
    onViewChange(views.find((view) => view.name === value));
  };

  const handleConfigurationView = (viewName?: string) => {
    onConfigureView(views.find((view) => view.name === viewName));
  };

  return (
    <>
      {loading && !loadingExport && !pagination && fieldConfiguration ? (
        <SkeletonList />
      ) : (
        <Card>
          <div className="row pt-4 px-4">
            <div className="col flex-left">
              <h4 className="margin-bottom text-primary mr-3 mb-0">
                {connectionName}
              </h4>
              <span>{connectionSourceName}</span>
            </div>
            <div className="col-auto flex-right">
              <DitButton
                className="export mr-2"
                label="Export all data"
                color="primary"
                loading={loadingExport}
                onClick={onExport}
              />
              <DitButton
                className="export-excel"
                label="Export View"
                color="primary"
                loading={loadingExportExcel}
                onClick={onExportExcel}
              />
            </div>
          </div>

          <Formik
            initialValues={{ view: currentView }}
            enableReinitialize={true}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="row px-4">
                  <div className="col flex-left">
                    {!!views?.length && (
                      <>
                        <span className="text-primary mr-2">Select view:</span>
                        <Field
                          name="view"
                          component={DitSelect}
                          options={views.map((view) => ({
                            label: view.name,
                            value: view.name
                          }))}
                          className="w-50"
                          onChange={(value) =>
                            handleViewSelect(value, setFieldValue)
                          }
                        />
                      </>
                    )}
                  </div>

                  <div className="col-auto flex-right">
                    {!!values.view && (
                      <DitButton
                        label="Edit view"
                        className="text-primary border-primary bg-white mr-2"
                        onClick={() => handleConfigurationView(values.view)}
                      />
                    )}
                    <DitButton
                      label="New view"
                      className="text-primary border-primary bg-white "
                      onClick={() => handleConfigurationView()}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {!list.length && !fieldConfiguration.length ? (
            <DitEmptyState message="This connection has no records" />
          ) : (
            <>
              {!fieldConfiguration.length ? (
                <DitEmptyState message="Please select some list columns" />
              ) : (
                <>
                  <div className="row px-4 py-3">
                    {fieldConfiguration.map((field, index) => {
                      return (
                        <div className="col flex-left" key={index}>
                          <DitTextTruncateTooltip label={field.value} />
                        </div>
                      );
                    })}
                    <div className="col-1 flex-right">
                      <DitTableHeaderColumn label="Actions" sortable={false} />
                    </div>
                  </div>

                  <div className="row px-4 pb-3 border-bottom">
                    {fieldConfiguration.map((field, index) => {
                      return (
                        <div className="col flex-left" key={index}>
                          <DitSearchbar
                            hideIcon={true}
                            onChange={(event) =>
                              handleColumnChange(field.value, event.search)
                            }
                          />
                        </div>
                      );
                    })}
                    <div className="col-1"></div>
                  </div>

                  {list.map((record, index) => (
                    <div
                      key={index}
                      className="row list-row px-4 py-3 border-bottom font-weight-light"
                    >
                      {fieldConfiguration.map((field, index) => {
                        return (
                          <div
                            key={index}
                            className="col"
                            style={{
                              maxWidth: `${
                                100 / fieldConfiguration.length -
                                8.33 / fieldConfiguration.length
                              }%`
                            }}
                          >
                            <DitTextTruncateTooltip
                              label={
                                Moment(
                                  record[field.value],
                                  Moment.ISO_8601
                                ).isValid()
                                  ? Moment(record[field.value]).format(
                                      'YYYY-MM-DD'
                                    )
                                  : record[field.value]
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="col-1 flex-right">
                        <DitIconButton
                          className="px-2 py-2 text-primary"
                          icon="la-eye"
                          onClick={() => onShowDetail(record)}
                        />
                      </div>
                    </div>
                  ))}

                  <DitTableFooter
                    pagination={pagination}
                    onPaginationChange={onFilterChange}
                  />
                </>
              )}
            </>
          )}
        </Card>
      )}
    </>
  );
};
