import { displayToastr, ToastrSeverities } from '@app/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { ConfirmForgotPasswordPayload, SignInRequest } from '../models';
import { AuthService } from '../services';

export enum AuthActionTypes {
  SignIn = '[Auth] Sign in',
  SignInSuccess = '[Auth] Sign in success',
  SignInFailed = '[Auth] Sign in failed',
  SignOut = '[Auth] Sign out',
  RequestPasswordRecoveryCode = '[Auth] Recover password',
  RequestPasswordRecoveryCodeSuccess = '[Auth] Recover password success',
  RequestPasswordRecoveryCodeFailed = '[Auth] Recover password failed',
  ConfirmForgotPassword = '[Auth] Confirm forgot password',
  ConfirmForgotPasswordSuccess = '[Auth] Confirm forgot success',
  ConfirmForgotPasswordFailed = '[Auth] Confirm forgot password failed',
  ResetPasswordRecoveryEmailSent = '[Auth] Reset password recovery email sent'
}
export interface SignIn {
  type: AuthActionTypes.SignIn;
  payload: SignInRequest;
}
export interface SignInSuccess {
  type: AuthActionTypes.SignInSuccess;
  payload: CognitoUserSession;
}
export interface SignInFailed {
  type: AuthActionTypes.SignInFailed;
}
export interface SignOut {
  type: AuthActionTypes.SignOut;
}
export interface RequestPasswordRecoveryCode {
  type: AuthActionTypes.RequestPasswordRecoveryCode;
  payload: string;
}
export interface RequestPasswordRecoveryCodeSuccess {
  type: AuthActionTypes.RequestPasswordRecoveryCodeSuccess;
  payload: string;
}
export interface RequestPasswordRecoveryCodeFailed {
  type: AuthActionTypes.RequestPasswordRecoveryCodeFailed;
}
export interface ConfirmForgotPassword {
  type: AuthActionTypes.ConfirmForgotPassword;
  payload: ConfirmForgotPasswordPayload;
}
export interface ConfirmForgotPasswordSuccess {
  type: AuthActionTypes.ConfirmForgotPasswordSuccess;
}
export interface ConfirmForgotPasswordFailed {
  type: AuthActionTypes.ConfirmForgotPasswordFailed;
}
export interface ResetPasswordRecoveryEmailSent {
  type: AuthActionTypes.ResetPasswordRecoveryEmailSent;
}

export type AuthActions =
  | SignIn
  | SignInSuccess
  | SignInFailed
  | SignOut
  | RequestPasswordRecoveryCode
  | RequestPasswordRecoveryCodeSuccess
  | RequestPasswordRecoveryCodeFailed
  | ResetPasswordRecoveryEmailSent;

export const signIn = (payload: SignInRequest) => (dispatch: Dispatch) => {
  dispatch({ type: AuthActionTypes.SignIn, payload });
  AuthService.signIn(payload, dispatch);
};

export const signInSuccess = (payload: CognitoUserSession): SignInSuccess => ({
  type: AuthActionTypes.SignInSuccess,
  payload
});

export const signInFailed = (): SignInFailed => ({
  type: AuthActionTypes.SignInFailed
});

export const signOut = () => (dispatch: Dispatch): void => {
  dispatch({ type: AuthActionTypes.SignOut });
  localStorage.clear();
  dispatch(push('/spa'));
};

export const recoverPassword = (payload: string) => async (dispatch) => {
  dispatch({ type: AuthActionTypes.RequestPasswordRecoveryCode, payload });
  try {
    await AuthService.recoverPassword(payload);
    dispatch(recoverPasswordSuccess(payload));
  } catch (e) {
    dispatch(recoverPasswordFailed());
  }
};

export const recoverPasswordSuccess = (
  payload: string
): RequestPasswordRecoveryCodeSuccess => ({
  type: AuthActionTypes.RequestPasswordRecoveryCodeSuccess,
  payload
});

export const recoverPasswordFailed = (): RequestPasswordRecoveryCodeFailed => ({
  type: AuthActionTypes.RequestPasswordRecoveryCodeFailed
});

export const confirmForgotPassword = (
  payload: ConfirmForgotPasswordPayload
) => async (dispatch) => {
  dispatch({ type: AuthActionTypes.ConfirmForgotPassword, payload });
  try {
    await AuthService.confirmForgotPassword(payload);
    dispatch(confirmForgotPasswordSuccess());
    dispatch(
      signIn({ username: payload.username, password: payload.password })
    );
    dispatch(
      displayToastr({
        message: 'Password updated successfully!',
        severity: ToastrSeverities.Success
      })
    );
  } catch (e) {
    dispatch(confirmForgotPasswordFailed());
  }
};

export const confirmForgotPasswordSuccess = (): ConfirmForgotPasswordSuccess => ({
  type: AuthActionTypes.ConfirmForgotPasswordSuccess
});

export const confirmForgotPasswordFailed = (): ConfirmForgotPasswordFailed => ({
  type: AuthActionTypes.ConfirmForgotPasswordFailed
});

export const resetPasswordRecoveryEmailSent = (): ResetPasswordRecoveryEmailSent => ({
  type: AuthActionTypes.ResetPasswordRecoveryEmailSent
});
