import React from 'react';
import { Link } from 'react-router-dom';

import { RequestPasswordRequest, SetPasswordWithCodePayload } from '../models';
import { RequestPasswordCodeForm } from './request-password-code-form';
import { RequestPasswordUsernameForm } from './request-password-username-form';

interface RequestPasswordFormProps {
  emailSent: boolean;
  loading: boolean;
  onSubmit: (payload: RequestPasswordRequest) => void;
  onSubmitNewPassword: (payload: SetPasswordWithCodePayload) => void;
}

export const RequestPasswordForm = ({
  emailSent,
  loading,
  onSubmit,
  onSubmitNewPassword
}: RequestPasswordFormProps) => {
  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="row mb-3">
          <div className="col">
            <Link to="/auth/sign-in" className="text-primary flex-left">
              <i className="las la-angle-left"></i>
              Back
            </Link>
          </div>
        </div>

        {!emailSent ? (
          <RequestPasswordUsernameForm loading={loading} onSubmit={onSubmit} />
        ) : (
          <RequestPasswordCodeForm
            loading={loading}
            onSubmit={onSubmitNewPassword}
          />
        )}
      </div>
    </div>
  );
};
