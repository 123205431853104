import { User } from '@app/users';
import { Avatar, Card } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface UserProfileCardProps {
  item: User;
  queryParams: string;
}

export const UserProfileCard = ({
  item,
  queryParams
}: UserProfileCardProps) => {
  return (
    <Card className="py-3 px-4">
      <div className="row pt-4">
        <div className="col">
          <Avatar className="p-5 h1 text-primary bg-light">
            {item?.name.charAt(0).toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className="row py-3 h3 my-0">
        <div className="col">
          <span>{item?.name}</span>
          <span className="text-primary"> - {item?.employee_id}</span>
        </div>
      </div>

      <div className="row py-2">
        <div className="col flex-left">
          <i className="las la-envelope text-primary mr-2"></i>
          <span>{item?.email}</span>
        </div>
        <div className="col flex-left">
          <i className="las la-mobile text-primary mr-2"></i>
          <span>{item?.phone}</span>
        </div>
      </div>

      <div className="row py-2">
        <div className="col flex-left">
          <i className="las la-industry text-primary mr-2"></i>
          <span>{item?.company}</span>
        </div>
        <div className="col flex-left">
          <i className="las la-user text-primary mr-2"></i>
          <span>{item?.title}</span>
        </div>
      </div>

      <div className="row pt-2 pb-4 border-bottom">
        <div className="col flex-left">
          <i className="las la-laptop text-primary mr-2"></i>
          <span>{item?.role}</span>
        </div>
        <div className="col flex-left">
          <i className="las la-at text-primary mr-2"></i>
          <span>{item?.email}</span>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-9 flex-left">
          <NavLink
            to={{
              pathname: `/spa/settings/users/${item?.username}/records`,
              search: queryParams
            }}
            className="p-3 w-100 flex-left"
            activeClassName="bg-light text-primary rounded"
          >
            <i className="las la-redo-alt mr-3"></i>
            <span>Last update</span>
          </NavLink>
        </div>
      </div>
      <div className="row pb-3 ">
        <div className="col-9 flex-left">
          <NavLink
            to={{
              pathname: `/spa/settings/users/${item?.username}/change-password`,
              search: queryParams
            }}
            className="p-3 w-100 flex-left"
            activeClassName="bg-light text-primary rounded"
          >
            <i className="las la-user-shield mr-3"></i>
            <span>Change password</span>
          </NavLink>
        </div>
      </div>
    </Card>
  );
};
