import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import { DitQueryParams } from '@ditmac/data';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUserRecords } from '..';
import { UserRecordList } from '../components';

const UserRecordListContainer = ({ list, dispatch, pagination, loading }) => {
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserRecords({ username: id }));
  }, [dispatch, id]);

  const handleFilterChange = (queryParams: Partial<DitQueryParams>) => {
    dispatch(getUserRecords({ username: id, queryParams }));
  };

  return (
    <UserRecordList
      list={list}
      pagination={pagination}
      loading={loading}
      onFilterChange={handleFilterChange}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.userRecords.entities,
  pagination: state.userRecords.pagination,
  loading: getLoading(state)
});

export default connect(mapStateToProps)(UserRecordListContainer);
