/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { signOut } from '@app/auth';
import { DitButton } from '@ditmac/is-ui';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, dispatch } = this.props;

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile text-white">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile text-white">
                {user?.name}
              </span>
            )}

            {showAvatar && (
              <Avatar className="bg-light text-primary">
                {user?.name.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm">
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x bg-white">
            <Avatar className="large-avatar bg-light text-primary">
              {user?.name.charAt(0).toUpperCase()}
            </Avatar>
            <div className="kt-user-card__name text-dark">{user?.name}</div>
          </div>
          <div className="kt-notification menu-item">
            <div className="kt-notification__custom">
              <div className="row mx-0">
                <div className="col">
                  <Link to="/spa/my-account" className="flex-left">
                    <h5 className="mb-0 mr-2">My Account</h5>
                    <i className="las la-user-circle"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-notification">
            <div className="kt-notification__custom">
              <DitButton
                color="primary"
                label="Sign out"
                onClick={() => dispatch(signOut())}
              />
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
