import { ToastrPayload } from '../models';

export enum CoreActionTypes {
  DisplayToastr = '[Core] Display toastr',
  ClearToastr = '[Core] Clear toastr',
  SetLoadingGet = '[Core] Set loading get',
  SetLoadingElse = '[Core] Set loading else'
}

export const displayToastr = (payload: ToastrPayload) => ({
  type: CoreActionTypes.DisplayToastr,
  payload
});

export const clearToastr = () => ({
  type: CoreActionTypes.ClearToastr
});

export const setLoadingGet = (payload: boolean) => ({
  type: CoreActionTypes.SetLoadingGet,
  payload
});

export const setLoadingElse = (payload: boolean) => ({
  type: CoreActionTypes.SetLoadingElse,
  payload
});
