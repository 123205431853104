import './select.scss';

import { capitalize } from '@ditmac/utils';
import { FieldInputProps, FormikProps, getIn } from 'formik';
import React from 'react';
import Select from 'react-select';

import { DitError } from './error';

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: '#F2F3F8',
    borderWidth: '1px',
    width: '100%',
    ':active': {
      borderColor: '#007aff'
    },
    ':hover': {
      borderColor: '#f8f9fd'
    }
  }),
  input: (styles) => ({
    ...styles,
    height: '34px',
    paddingTop: '6px',
    paddingBotton: '6px',
    margin: '0'
  }),
  indicatorSeparator: () => ({ display: 'none' })
};

export interface SelectOption {
  label: string;
  value: any;
  selected?: boolean;
  type?: any;
}

interface DitSelectProps {
  label?: string;
  className?: string;
  options: SelectOption[];
  menuPlacement?: string;
  isMulti?: boolean;
  placeholder?: string;
  value?: SelectOption[];
  hideLabel?: boolean;
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const DitSelect = ({
  label,
  className,
  options,
  menuPlacement,
  isMulti,
  placeholder,
  value,
  hideLabel,
  field,
  form,
  disabled,
  onChange
}: DitSelectProps) => {
  const errorMessage = getIn(form?.errors, field?.name);
  const touched = getIn(form?.touched, field?.name);
  const hasError = errorMessage && touched;

  const handleChange = (option: SelectOption) => {
    if (form) {
      form.setFieldValue(field.name, option.value);
    }
    if (onChange) {
      option?.value ? onChange(option.value) : onChange(option);
    }
  };

  return (
    <div className={`form-group my-2 dit-select ${className || ''}`}>
      {label && !hideLabel && <label>{label}</label>}
      <Select
        className={`${hasError ? 'border rounded border-danger' : ''} `}
        value={
          field
            ? options?.find((option) => option.value === field.value) || null
            : value
        }
        options={options}
        isOptionDisabled={(option) => option.disabled}
        styles={styles}
        placeholder={placeholder || 'Select'}
        menuPlacement={menuPlacement || 'bottom'}
        isMulti={isMulti}
        onChange={handleChange}
        isDisabled={disabled}
      />
      {hasError && <DitError label={capitalize(errorMessage)} />}
    </div>
  );
};
