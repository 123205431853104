import './table-footer.scss';

import { DEFAULT_PAGE_SIZE, DitQueryParams, Pagination } from '@ditmac/data';
import React from 'react';

import { DitButton } from '../button';
import { DitIconButton } from '../icon-button';
import { DitSelect, SelectOption } from '../select';
import { getEnd, getShowingNumber, getStart } from './pagination-functions';

interface DitTableFooterProps {
  pagination: Pagination;
  onPaginationChange: (queryParams: Partial<DitQueryParams>) => void;
}

export const DitTableFooter = ({
  pagination,
  onPaginationChange
}: DitTableFooterProps) => {
  const { currentPage, pageCount, pageSize, totalCount } = pagination || {
    currentPage: 0,
    pageCount: 0,
    pageSize: 0,
    totalCount: 0
  };
  const arraySize = (pageCount || 0) + 1;
  const pageSizeOptions: SelectOption[] = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ];

  const handlePageSizeChange = (value: number) => {
    onPaginationChange({ pageSize: value });
  };

  const handleCurrentPageChange = (value: number) => {
    onPaginationChange({ page: value });
  };

  return (
    <>
      {!!totalCount && (
        <div className="row bg-white py-4 px-4 rounded-bottom">
          <div className="col">
            <DitIconButton
              icon="la-angle-double-left"
              className="mr-2 text-primary bg-light"
              disabled={currentPage === 1}
              onClick={() => handleCurrentPageChange(1)}
            />
            <DitIconButton
              icon="la-angle-left"
              className="mr-4 text-primary bg-light"
              disabled={currentPage === 1}
              onClick={() => handleCurrentPageChange(currentPage - 1)}
            />

            {Array(arraySize)
              .fill(0)
              .map((_, index) => index)
              .slice(1, arraySize)
              .slice(getStart(currentPage), getEnd(currentPage))
              .map((index) => (
                <DitButton
                  key={index}
                  label={String(index)}
                  color={index === currentPage ? 'primary' : ''}
                  className="rounded-circle s-30 text-black bg-white"
                  noBorder
                  onClick={() => handleCurrentPageChange(index)}
                />
              ))}

            <DitIconButton
              icon="la-angle-right"
              className="ml-4 mr-2 text-primary bg-light"
              disabled={currentPage === pageCount}
              onClick={() => handleCurrentPageChange(currentPage + 1)}
            />
            <DitIconButton
              icon="la-angle-double-right"
              className="mr-5 text-primary bg-light"
              disabled={currentPage === pageCount}
              onClick={() => handleCurrentPageChange(pageCount)}
            />
          </div>
          <div className="col-4 flex-right">
            <DitSelect
              className="mr-2"
              menuPlacement="top"
              value={pageSizeOptions.filter(
                (option) => option.value === (pageSize || DEFAULT_PAGE_SIZE)
              )}
              options={pageSizeOptions}
              onChange={handlePageSizeChange}
            />
            <span>
              Showing {currentPage * pageSize - pageSize + 1}-
              {getShowingNumber(currentPage, pageSize, totalCount)} of{' '}
              {totalCount}
            </span>
          </div>
        </div>
      )}
    </>
  );
};
