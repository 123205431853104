export enum FilterTypes {
  Equal = '=',
  NotEqual = '!',
  LessThanOrEqual = '<=',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  GreaterThan = '>',
  Contains = '*',
  NotContains = '!*',
  BeginsWith = '^',
  In = 'IN',
  Between = '><',
  Exists = '?',
  NotExists = '!?'
}
