import { DitButton, DitInput } from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Role } from '../models';

interface RoleDetailProps {
  item: Role;
  loading: boolean;
  onSubmit: (role: Role) => void;
}

const RoleDetailValidation = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required()
});

export const RoleDetail = ({ loading, item, onSubmit }: RoleDetailProps) => {
  const initialValues = {
    role_id: item?.role_id,
    name: item?.name,
    description: item?.description
  };

  return (
    <Card className="p-3">
      <Formik
        initialValues={initialValues}
        validationSchema={RoleDetailValidation}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="row">
            <div className="col-4">
              <Field
                component={DitInput}
                label="Role name"
                placeholder="Enter name"
                name="name"
              />
            </div>
            <div className="col-6">
              <Field
                component={DitInput}
                label="Role description"
                placeholder="Enter description"
                name="description"
              />
            </div>
            <div className="col-2 flex-end pb-2">
              <DitButton
                loading={loading}
                label="Save changes"
                color="primary"
                type="submit"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </Card>
  );
};
