import { BreadcumItem } from '@ditmac/is-ui';
import { useQuery } from '@ditmac/utils';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserProfileCardContainer from './containers/user-profile-card-container';
import UserProfileChangePasswordContainer from './containers/user-profile-change-password-container';
import UserProfileRecordListContainer from './containers/user-profile-record-list-container';

export const UserProfile = () => {
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  return (
    <div className="row">
      <div className="col-5">
        <UserProfileCardContainer />
      </div>
      <div className="col-7">
        <Switch>
          <Redirect
            exact
            from="/spa/settings/users/:id"
            to={`/spa/settings/users/:id/records?breadcum=${JSON.stringify(breadcum)}`}
          />
          <Route path="/spa/settings/users/:id/records" exact={true}>
            <UserProfileRecordListContainer />
          </Route>
          <Route path="/spa/settings/users/:id/change-password" exact={true}>
            <UserProfileChangePasswordContainer />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
