import { displayToastr, setLoadingElse, ToastrSeverities } from '@app/core';
import { environment } from '@env/environment';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import axios, { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { ConfirmForgotPasswordPayload, SignInRequest } from '../models';
import { signInFailed, signInSuccess } from '../state';

export class AuthService {
  static signIn(signInRequest: SignInRequest, dispatch: Dispatch): void {
    dispatch(setLoadingElse(true));
    const poolData = {
      UserPoolId: environment.cognitoPoolId,
      ClientId: environment.cognitoClientId
    };

    const userPool = new CognitoUserPool(poolData);

    const user = new CognitoUser({
      Username: signInRequest.username,
      Pool: userPool
    });

    const authDetails = new AuthenticationDetails({
      Username: signInRequest.username,
      Password: signInRequest.password
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data: CognitoUserSession) =>
        this.signInSuccess(data, dispatch),
      onFailure: (error) => this.signInFailed(error, dispatch),
      newPasswordRequired: (data) =>
        user.completeNewPasswordChallenge(
          signInRequest.password,
          { USERNAME: data.username },
          {
            onSuccess: (data: CognitoUserSession) =>
              this.signInSuccess(data, dispatch),
            onFailure: (error) => this.signInFailed(error, dispatch)
          }
        )
    });
  }

  static signInSuccess(data: CognitoUserSession, dispatch: Dispatch): void {
    dispatch(setLoadingElse(false));
    localStorage.setItem('accessToken', data.getAccessToken().getJwtToken());
    localStorage.setItem('IdToken', data.getIdToken().getJwtToken());
    dispatch(signInSuccess(data));
    dispatch(push('/spa'));
  }

  static signInFailed(error, dispatch: Dispatch): void {
    dispatch(setLoadingElse(false));
    dispatch(signInFailed());
    dispatch(
      displayToastr({
        message: error.message,
        severity: ToastrSeverities.Error
      })
    );
  }

  static recoverPassword(username: string): Promise<AxiosResponse<any>> {
    return axios.post(`${environment.apiUrl}/forgot-password`, { username });
  }

  static confirmForgotPassword(
    payload: ConfirmForgotPasswordPayload
  ): Promise<AxiosResponse<any>> {
    return axios.post(`${environment.apiUrl}/forgot-password/confirm`, payload);
  }
}
