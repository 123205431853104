import React from 'react';

interface DitErrorProps {
  label: string;
}

export const DitError = ({ label }: DitErrorProps) => {
  return (
    <span className="small text-danger flex-left mt-2">
      <i className="las la-exclamation-circle"></i>
      {label}
    </span>
  );
};
