import { getLoading, getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { LanguageTimeZone } from '../components';
import { AccountPreferences } from '../models';
import {
  createAccountPreferences,
  getAccountPreferences,
  getPreferenceOptions,
  updateAccountPreferences
} from '../state';

const LanguageTimeZoneContainer = ({
  loading,
  loadingElse,
  hasPreferences,
  preferenceOptions,
  accountPreferences,
  dispatch
}) => {
  useEffect(() => {
    dispatch(getPreferenceOptions());
    dispatch(getAccountPreferences());
  }, [dispatch]);

  const handleSubmit = (value: AccountPreferences) => {
    hasPreferences
      ? dispatch(updateAccountPreferences(value))
      : dispatch(createAccountPreferences(value));
  };

  return (
    <>
      {accountPreferences && (
        <LanguageTimeZone
          accountPreferences={accountPreferences}
          preferenceOptions={preferenceOptions}
          loading={loading}
          loadingElse={loadingElse}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  preferenceOptions: state.accountPreferences.preferenceOptions,
  hasPreferences: state.accountPreferences.hasPreferences,
  loading: getLoading(state),
  loadingElse: getLoadingElse(state),
  accountPreferences: state.accountPreferences.accountPrefernces
});

export default connect(mapStateToProps)(LanguageTimeZoneContainer);
