import { DitQueryParams, Pagination } from '@ditmac/data';
import {
  DitIconButton,
  DitSearchbar,
  DitTableFooter,
  DitTableHeaderColumn
} from '@ditmac/is-ui';
import { Card } from '@material-ui/core';
import Moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { UserRecord } from '../models';

interface UserRecordListProps {
  list: UserRecord[];
  pagination: Pagination;
  loading: boolean;
  onFilterChange: (queryParams: Partial<DitQueryParams>) => void;
}

export const UserRecordList = ({
  list,
  pagination,
  loading,
  onFilterChange
}: UserRecordListProps) => {
  return (
    <Card>
      {loading && !pagination ? (
        <Skeleton height={40} count={10} />
      ) : (
        <>
          <div className="row p-3 pt-4">
            <div className="col flex-left">
              <h4 className="margin-bottom text-primary mb-0">Last update:</h4>
            </div>
          </div>

          <div className="row my-3 mx-0 px-3">
            <div className="col-5">
              <DitSearchbar onChange={onFilterChange} />
            </div>
          </div>

          <div className="row p-3">
            <div className="col">
              <DitTableHeaderColumn label="Connection" sortable={false} />
            </div>
            <div className="col">
              <DitTableHeaderColumn label="Facility Name" sortable={false} />
            </div>
            <div className="col">
              <DitTableHeaderColumn label="Source" sortable={false} />
            </div>
            <div className="col">
              <DitTableHeaderColumn label="Date" sortable={false} />
            </div>
            <div className="col">
              <DitTableHeaderColumn label="Type" sortable={false} />
            </div>
            <div className="col flex-right">
              <DitTableHeaderColumn label="Actions" sortable={false} />
            </div>
          </div>
          {/* <div className="row px-3 pb-3 border-bottom">
        <div className="col">
          <DitInput placeholder="Search" />
        </div>
        <div className="col">
          <DitSelect options={[]} />
        </div>
        <div className="col">
          <DitSelect options={[]} />
        </div>
        <div className="col">
          <DitDateFilter />
        </div>
        <div className="col">
          <DitSelect options={[]} />
        </div>
        <div className="col flex-right pt-2">
          <DitIconButton
            className="px-2 py-3 mr-2 rounded"
            color="primary"
            icon="la-search"
          />
          <DitIconButton className="px-2 py-2 rounded" icon="la-redo-alt" />
        </div>
      </div> */}
          {list.map((record, index) => (
            <div
              key={index}
              className="row list-row px-4 border-bottom font-weight-light"
            >
              <div className="col-3">{record.action}</div>
              <div className="col-3">
                {Moment(record.created_at).format('MM/DD/YYYY')}
              </div>
              <div className="col-3">{record.record_type}</div>
              <div className="col-3 flex-right">
                <DitIconButton
                  className="px-2 py-2 text-primary"
                  icon="la-eye"
                />
              </div>
            </div>
          ))}
          <DitTableFooter
            pagination={pagination}
            onPaginationChange={onFilterChange}
          />
        </>
      )}
    </Card>
  );
};
