import { getLoading } from '@app/core';
import { AppState, getState } from '@app/state';
import { DitQueryParams } from '@ditmac/data';
import { BreadcumItem } from '@ditmac/is-ui';
import { sortObjectArrayAlphabetically, useQuery } from '@ditmac/utils';
import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ConnectionSourceDetailExportDialog,
  ConnectionSourceDetailList
} from '../components';
import { ConnectionSourceDetailDialog } from '../components/connection-source-detail-dialog';
import { ConnectionSourceDetailView, ConnectionSourceRecord } from '../models';
import {
  exportConnectionDetails,
  exportConnectionDetailsExcel,
  getConnectionSourceDetailFields,
  getConnectionSourceDetails,
  resetConnectionDetails,
  setConnectionSourceDetailViewToEdit,
  setCurrentConnectionSourceDetailView
} from '../state/connection-source-detail.actions';
import ViewconfigurationDialogContainer from './view-configuration-dialog-container';

const ConnectionSourceDetailListContainer = ({
  dispatch,
  entities,
  exportOptions,
  views,
  loading,
  loadingExport,
  loadingExportExcel,
  pagination,
  username,
  queryParams,
  currentView
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<ConnectionSourceRecord>(null);
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [openConfigureView, setOpenConfigureView] = useState<boolean>(false);
  const query = useQuery();
  const breadcum: BreadcumItem[] = JSON.parse(query.get('breadcum')) || [];
  const connectionName = breadcum[0]?.name;
  const connectionSourceName = breadcum[1]?.name;
  const { integrationId, moduleId } = useParams();

  useEffect(() => {
    dispatch(
      getConnectionSourceDetails({
        integrationId,
        moduleId
      })
    );
    return () => {
      dispatch(resetConnectionDetails());
    };
  }, [dispatch, integrationId, moduleId]);

  useEffect(() => {
    dispatch(
      getConnectionSourceDetailFields({
        integrationId,
        moduleId,
        username
      })
    );
  }, [dispatch, integrationId, moduleId, username]);

  useEffect(() => {
    setOpenExport(!!exportOptions.length);
  }, [exportOptions]);

  const handleExport = () => {
    dispatch(
      exportConnectionDetails({
        integrationId,
        moduleId
      })
    );
  };

  const handleShowDetail = (record: ConnectionSourceRecord) => {
    setOpen(true);
    setItem(record);
  };

  const handleFilterChange = (payload: DitQueryParams) => {
    const queryParams = {
      ...getState().connectionSourceDetail.queryParams,
      ...payload
    };
    dispatch(
      getConnectionSourceDetails({
        integrationId,
        moduleId,
        queryParams
      })
    );
  };

  const handleConfigureView = (view?: ConnectionSourceDetailView) => {
    dispatch(setConnectionSourceDetailViewToEdit(view));
    setOpenConfigureView(true);
  };

  const handleExportExcel = () => {
    dispatch(
      exportConnectionDetailsExcel({
        integrationId,
        moduleId,
        queryParams
      })
    );
  };

  const handleViewChange = (view: ConnectionSourceDetailView) => {
    dispatch(setCurrentConnectionSourceDetailView(view));
  };

  return (
    <>
      <ConnectionSourceDetailList
        connectionName={connectionName}
        connectionSourceName={connectionSourceName}
        list={entities}
        loading={loading}
        loadingExport={loadingExport}
        loadingExportExcel={loadingExportExcel}
        pagination={pagination}
        fieldConfiguration={
          sortObjectArrayAlphabetically(currentView?.fields, 'name') || []
        }
        views={sortObjectArrayAlphabetically(views, 'name')}
        currentView={currentView?.name}
        onFilterChange={handleFilterChange}
        onExport={handleExport}
        onShowDetail={handleShowDetail}
        onConfigureView={handleConfigureView}
        onExportExcel={handleExportExcel}
        onViewChange={handleViewChange}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ConnectionSourceDetailDialog id="record-form" item={item} />
      </Dialog>
      <Dialog open={openExport} onClose={() => setOpenExport(false)}>
        <ConnectionSourceDetailExportDialog exportOptions={exportOptions} />
      </Dialog>
      <Dialog
        open={openConfigureView}
        onClose={() => setOpenConfigureView(false)}
      >
        <ViewconfigurationDialogContainer
          currentView={currentView}
          onDone={() => setOpenConfigureView(false)}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  entities: state.connectionSourceDetail.entities,
  exportOptions: state.connectionSourceDetail.exportOptions,
  views: state.connectionSourceDetail.views,
  loading: getLoading(state),
  loadingExport: state.connectionSourceDetail.loadingExport,
  loadingExportExcel: state.connectionSourceDetail.loadingExportExcel,
  pagination: state.connectionSourceDetail.pagination,
  username: state.auth.user && state.auth.user['cognito:username'],
  queryParams: state.connectionSourceDetail.queryParams,
  currentView: state.connectionSourceDetail.currentView
});

export default connect(mapStateToProps)(ConnectionSourceDetailListContainer);
