import './icon-button.scss';

import { IconButton } from '@material-ui/core';
import React from 'react';

interface DitButtonProps {
  id?: string;
  icon?: string;
  color?: 'primary' | 'secondary' | 'default';
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  disabled?: boolean;
  className?: string;
  onClick?: any;
}

export const DitIconButton = (props: DitButtonProps) => {
  return (
    <IconButton
      color={props.color || 'default'}
      onClick={props.onClick}
      className={`dit-icon-button ${props.className || ''}`}
      disabled={props.disabled}
    >
      {props.icon && <i className={`las ${props.icon}`}></i>}
      {props.label}
    </IconButton>
  );
};
