import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

interface DitTextTruncateTooltip {
  label: string;
}

export const DitTextTruncateTooltip = ({ label }: DitTextTruncateTooltip) => {
  return (
    <Tooltip title={label || ''} placement="top">
      <span className="text-truncate">{label}</span>
    </Tooltip>
  );
};
