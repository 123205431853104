import { displayToastr, ToastrSeverities } from '@app/core';
import { DitQueryParams } from '@ditmac/data';
import { push } from 'connected-react-router';

import { Role } from '../models';
import { GetRolesResponse } from '../models/get-roles-response.model';
import { RolesServices } from '../services';

export enum RolesActionTypes {
  GetRoles = '[Roles] Get roles',
  GetRolesSuccess = '[Roles] Get roles success',
  GetRolesFailed = '[Roles] Get roles failed',
  GetRole = '[Roles] Get role',
  GetRoleSuccess = '[Roles] Get role success',
  GetRoleFailed = '[Roles] Get role failed',
  CreateRole = '[Roles] Create role',
  CreateRoleSuccess = '[Roles] Create role success',
  CreateRoleFailed = '[Roles] Create role failed',
  UpdateRole = '[Roles] Update role',
  UpdateRoleSuccess = '[Roles] Update role success',
  UpdateRoleFailed = '[Roles] Update role failed'
}
export interface GetRoles {
  type: RolesActionTypes.GetRoles;
  payload?: DitQueryParams;
}
export interface GetRolesSuccess {
  type: RolesActionTypes.GetRolesSuccess;
  payload: GetRolesResponse;
}
export interface GetRolesFailed {
  type: RolesActionTypes.GetRolesFailed;
}
export interface GetRole {
  type: RolesActionTypes.GetRole;
  payload: string;
}
export interface GetRoleSuccess {
  type: RolesActionTypes.GetRoleSuccess;
  payload: Role;
}
export interface GetRoleFailed {
  type: RolesActionTypes.GetRoleFailed;
}
export interface CreateRole {
  type: RolesActionTypes.CreateRole;
  payload: Role;
}
export interface CreateRoleSuccess {
  type: RolesActionTypes.CreateRoleSuccess;
  payload: Role;
}
export interface CreateRoleFailed {
  type: RolesActionTypes.CreateRoleFailed;
}
export interface UpdateRole {
  type: RolesActionTypes.UpdateRole;
  payload: Partial<Role>;
}
export interface UpdateRoleSuccess {
  type: RolesActionTypes.UpdateRoleSuccess;
  payload: Partial<Role>;
}
export interface UpdateRoleFailed {
  type: RolesActionTypes.UpdateRoleFailed;
}

export type RolesActions =
  | GetRoles
  | GetRolesSuccess
  | GetRolesFailed
  | GetRole
  | GetRoleSuccess
  | GetRoleFailed
  | CreateRole
  | CreateRoleSuccess
  | CreateRoleFailed
  | UpdateRole
  | UpdateRoleSuccess
  | UpdateRoleFailed;

export const getRoles = (payload?: DitQueryParams) => async (dispatch) => {
  dispatch({ type: RolesActionTypes.GetRoles, payload });
  try {
    const response = await RolesServices.getAll(payload);
    dispatch(getRolesSuccess(response.data));
  } catch (e) {
    dispatch(getRolesFailed());
  }
};

export const getRolesSuccess = (
  payload: GetRolesResponse
): GetRolesSuccess => ({
  type: RolesActionTypes.GetRolesSuccess,
  payload
});

export const getRolesFailed = (): GetRolesFailed => ({
  type: RolesActionTypes.GetRolesFailed
});

export const getRole = (payload: string) => async (dispatch) => {
  dispatch({ type: RolesActionTypes.GetRole, payload });
  try {
    const response = await RolesServices.get(payload);
    dispatch(getRoleSuccess(response.data));
  } catch (e) {
    dispatch(getRoleFailed());
  }
};

export const getRoleSuccess = (payload: Role): GetRoleSuccess => ({
  type: RolesActionTypes.GetRoleSuccess,
  payload
});

export const getRoleFailed = (): GetRoleFailed => ({
  type: RolesActionTypes.GetRoleFailed
});

export const createRole = (payload: Role) => async (dispatch) => {
  dispatch({ type: RolesActionTypes.CreateRole, payload });
  try {
    const response = await RolesServices.create(payload);
    dispatch(createRoleSuccess(response.data));
    dispatch(push('/spa/settings/roles/1'));
  } catch (e) {
    dispatch(createRoleFailed());
  }
};

export const createRoleSuccess = (payload: Role): CreateRoleSuccess => ({
  type: RolesActionTypes.CreateRoleSuccess,
  payload
});

export const createRoleFailed = (): CreateRoleFailed => ({
  type: RolesActionTypes.CreateRoleFailed
});

export const updateRole = (payload: Partial<Role>) => async (dispatch) => {
  dispatch({ type: RolesActionTypes.UpdateRole, payload });
  try {
    await RolesServices.update(payload);
    dispatch(updateRoleSuccess(payload));
    dispatch(
      displayToastr({
        severity: ToastrSeverities.Success,
        message: 'Role updated successfully'
      })
    );
  } catch (e) {
    dispatch(updateRoleFailed());
  }
};

export const updateRoleSuccess = (
  payload: Partial<Role>
): UpdateRoleSuccess => ({
  type: RolesActionTypes.UpdateRoleSuccess,
  payload
});

export const updateRoleFailed = (): UpdateRoleFailed => ({
  type: RolesActionTypes.UpdateRoleFailed
});
