import {
  displayToastr,
  HttpMethods,
  setLoadingElse,
  setLoadingGet,
  ToastrSeverities
} from '@app/core';
import { HttpError } from '@app/core/models/http-error.model';
import { store } from '@app/state';
import axios from 'axios';

import { signOut } from '../state/auth.actions';

export const initInterceptors = () => {
  axios.interceptors.request.use(handleRequest);
  axios.interceptors.response.use(handleResponse, handleErrorResponse);
};

export const handleRequest = (config) => {
  const token = store.getState().auth.idToken;
  config.headers.Authorization = token;
  config.method === HttpMethods.Get
    ? store.dispatch(setLoadingGet(true))
    : store.dispatch(setLoadingElse(true));
  return config;
};

export const handleResponse = (config) => {
  config.config.method === HttpMethods.Get
    ? store.dispatch(setLoadingGet(false))
    : store.dispatch(setLoadingElse(false));
  return config;
};

export const handleErrorResponse = (error: { response?: HttpError }) => {
  store.dispatch(setLoadingElse(false));
  store.dispatch(setLoadingGet(false));
  if (!error.response) {
    store.dispatch(
      displayToastr({
        message: 'Your session has expired. Please sign in again',
        severity: ToastrSeverities.Error
      })
    );
    store.dispatch(signOut() as any);
  } else {
    const response = error.response;
    const status = response.status;
    const method = error.response.config.method;
    const url = error.response.config.url;

    const errorMessages = {
      400: `${status} - Bad request for method ${method} at ${url}`,
      // 401: `${status} - Unauthorized for method ${method} at ${url}`,
      404: `${status} - Resource not found for method ${method} at ${url}`,
      500: `${status} - Internal Server Error for method ${method} at ${url}`
    };

    const errorMessage = errorMessages[status];

    if (errorMessage) {
      store.dispatch(
        displayToastr({
          message: errorMessage,
          severity: ToastrSeverities.Error
        })
      );
    }
  }

  return Promise.reject(new Error(error.response.statusText));
};
