import { AppState } from '@app/state';
import React from 'react';
import { connect } from 'react-redux';

import { Toastr } from '../components';
import { clearToastr } from '../state';

const ToastrContainer = (props) => {
  const handleClose = () => {
    props.dispatch(clearToastr());
  };
  return (
    <>
      {props.toastr.message && (
        <Toastr toastr={props.toastr} onClose={handleClose} />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  toastr: state.core.toastr
});

export default connect(mapStateToProps)(ToastrContainer);
