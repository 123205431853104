import { DitDialogLayout, DitSuccessDialog } from '@ditmac/is-ui';
import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { User, UserForm } from '../models';
import { UserAccountDetailsForm } from '../models/user-account-details-form.model';
import { UserInfoForm } from '../models/user-info-form.model';
import { UserInfoFormComponent } from './user-info-form';

enum UserFormSteps {
  UsersInfo = 1,
  AccountDetails = 2,
  Finish = 3
}

interface UserFormProps {
  item: User;
  loading: boolean;
  done: boolean;
  onDone: () => void;
  onSubmit: (formValue: UserForm) => void;
}

export const UserFormComponent = ({
  item,
  loading,
  done,
  onDone,
  onSubmit
}: UserFormProps) => {
  const [step, setStep] = useState(UserFormSteps.UsersInfo);
  const [userInfo, setUserInfo] = useState<UserInfoForm>(null);
  const [userAccountDetails, setUserAccountDetails] = useState<
    UserAccountDetailsForm
  >(null);

  useEffect(() => {
    if (done) {
      setStep(UserFormSteps.Finish);
    }
  }, [done]);

  useEffect(() => {
    if (item) {
      setUserInfo(item);
      setUserAccountDetails({ username: item.username, password: '' });
    }
  }, [item]);

  const handleUserInfoSubmit = (userFormValue: UserInfoForm) => {
    // setStep(UserFormSteps.AccountDetails);
    // setUserInfo(userFormValue);
    onSubmit({
      ...userFormValue
    });
  };

  // const handleBack = (userAccountDetailsFormValue: UserAccountDetailsForm) => {
  //   setStep(UserFormSteps.UsersInfo);
  //   setUserAccountDetails(userAccountDetailsFormValue);
  // };

  // const handleUserAccountDetailsSubmit = (
  //   userAccountDetailsForm: UserAccountDetailsForm
  // ) => {
  //   onSubmit({
  //     ...userInfo,
  //     ...userAccountDetailsForm
  //   });
  // };

  return (
    <DitDialogLayout size="sm">
      {(step === UserFormSteps.UsersInfo ||
        step === UserFormSteps.AccountDetails) && (
        <div className="row pt-3 mb-5">
          <div className="col flex-center">
            <h4>User's Info</h4>
            {/* <Avatar
              className={`mr-2 ${
                step === UserFormSteps.UsersInfo && 'bg-primary text-white'
              }`}
            >
              1
            </Avatar> */}
            {/* <span
              className={`first step${
                step === UserFormSteps.UsersInfo && 'text-primary'
              }`}
            >
              User's Info
            </span> */}
          </div>
          {/* <div className="col-5 offset-1 flex-left">
            <Avatar
              className={`mr-2 ${
                step === UserFormSteps.AccountDetails && 'bg-primary text-white'
              }`}
            >
              2
            </Avatar>
            <span
              className={`second-step ${
                step === UserFormSteps.AccountDetails && 'text-primary'
              }`}
            >
              Account Details
            </span>
          </div> */}
        </div>
      )}
      {step === UserFormSteps.UsersInfo && (
        <UserInfoFormComponent
          item={userInfo}
          onSubmit={handleUserInfoSubmit}
        />
      )}
      {/* {step === UserFormSteps.AccountDetails && (
        <UserAccountDetailsFormComponent
          item={userAccountDetails}
          loading={loading}
          onSubmit={handleUserAccountDetailsSubmit}
          onBack={handleBack}
        />
      )} */}
      {step === UserFormSteps.Finish && (
        <DitSuccessDialog
          title={`It's done!`}
          subtitle="User has been added successfully"
          onDone={() => onDone()}
        />
      )}
    </DitDialogLayout>
  );
};
