import { DitButton, DitInput } from '@ditmac/is-ui';
import { phoneRegExp, usePatchForm } from '@ditmac/utils';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { UserInfoForm } from '../models/user-info-form.model';

export const userInfoFormInitialState: UserInfoForm = {
  name: '',
  email: '',
  phone: '',
  company: '',
  title: '',
  // employee_id: ''
};

const UserInfoFormValidation = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
  phone: Yup.string().required().matches(phoneRegExp, 'Invalid phone format +1 5555555'),
  company: Yup.string().required(),
  title: Yup.string().required(),
  // employee_id: Yup.string().required()
});

export interface UserInfoFormComponentProps {
  item: UserInfoForm;
  onSubmit: (form: UserInfoForm) => void;
}

export const UserInfoFormComponent = ({
  item,
  onSubmit
}: UserInfoFormComponentProps) => {
  return (
    <Formik
      initialValues={userInfoFormInitialState}
      validationSchema={UserInfoFormValidation}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePatchForm(item, setFieldValue);
        return (
          <Form>
            <div className="row">
              <div className="col">
                <Field
                  component={DitInput}
                  label="Name"
                  placeholder="Enter name"
                  name="name"
                />
              </div>
              <div className="col">
                <Field
                  component={DitInput}
                  label="Company"
                  placeholder="Enter Company"
                  name="company"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Field
                  component={DitInput}
                  label="Email address"
                  placeholder="Enter email"
                  name="email"
                />
              </div>
              <div className="col">
                <Field
                  component={DitInput}
                  label="Phone"
                  placeholder="Enter phone"
                  name="phone"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  component={DitInput}
                  label="Title"
                  placeholder="Enter title"
                  name="title"
                />
              </div>
              <div className="col">
                {/* <Field
                  component={DitInput}
                  label="Employee ID"
                  placeholder="Enter ID"
                  name="employee_id"
                /> */}
              </div>
            </div>
            <div className="row">
              <div className="col flex-right">
                <DitButton color="primary" label="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
