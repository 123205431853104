import { getLoading } from '@app/core';
import { AppState } from '@app/state';
import { DitQueryParams } from '@ditmac/data';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { MyConnectionsList } from '../components';
import { getMyConnections } from '../state/my-connections.actions';

const MyConnectionsListContainer = ({
  entities,
  dispatch,
  loading,
  pagination
}) => {
  useEffect(() => {
    dispatch(getMyConnections());
  }, [dispatch]);

  const handleFilterChange = (params: DitQueryParams) => {
    dispatch(getMyConnections(params));
  };

  return (
    <MyConnectionsList
      list={entities}
      loading={loading}
      pagination={pagination}
      onFilterChange={handleFilterChange}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  entities: state.myConnections.entities,
  pagination: state.myConnections.pagination,
  loading: getLoading(state)
});

export default connect(mapStateToProps)(MyConnectionsListContainer);
