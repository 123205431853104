import { getLoadingElse } from '@app/core';
import { AppState } from '@app/state';
import React from 'react';
import { connect } from 'react-redux';

import { SignInForm } from '../components';
import { SignInRequest } from '../models';
import { signIn } from '../state';

interface SignInFormContainerProps {
  dispatch: any;
  loading: boolean;
}

const SignInFormContainer = ({
  dispatch,
  loading
}: SignInFormContainerProps) => {
  const handleSubmit = (signInRequest: SignInRequest) => {
    dispatch(signIn(signInRequest));
  };
  return <SignInForm loading={loading} onSubmit={handleSubmit} />;
};

const mapStateToProps = (state: AppState) => ({
  loading: getLoadingElse(state)
});

export default connect(mapStateToProps)(SignInFormContainer);
